import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';

import { Header, Icon, Image } from 'semantic-ui-react';

import {
  actionSetParamNumber,
  actionSetScenario,
  actionSetToken,
  actionSetVariant,
} from '../../../../redux/actions/actionMain';
import store from '../../../../redux/store';
import styles from './HandyV1.module.scss';

function HandyV1(props) {
  const variant = useSelector((state) => state.main);

  useEffect(() => {
    if (variant.variant === '1') {
      store.dispatch(actionSetVariant('3'));
    }
    if (variant.variant === '2') {
      store.dispatch(actionSetVariant('4'));
    }
  });

  const push = useHistory();

  return (
    <div >
      <div>
        <img
          className={styles.Background}
          src={`${process.env.PUBLIC_URL}/assets/images/background_1.jpg`}
        />

      </div>
      <img
              className={styles.Iphone}
              src={`${process.env.PUBLIC_URL}/assets/images/handwithphone_push.png`}
            />
      <div className={styles.phone}>
      <div className={styles.hour}>
        <br />
        <br />
        <br />
        <br />
        <h1 className={styles.bumHead}>10:15</h1>
        <h1 className={styles.bumDead}>Mittwoch , 30. November</h1>
        <div
          className={styles.pushHead}
          onClick={() => {
            store.dispatch(actionSetScenario('3'));

            push.push('/storungenv3');
          }}
        >
          <div className={styles.item1}>
            <Image
              size="mini"
              src={`${process.env.PUBLIC_URL}/assets/images/blue_logo.png`}
            />
          </div>{' '}
          <div className={styles.item2}>Bahn Deutschland </div>
        </div>
        <div
          className={styles.pushNot}
          onClick={() => {
            store.dispatch(actionSetScenario('3'));

            push.push('/storungenv3');
          }}
        >
          <div
            className={styles.notInfo}
            onClick={() => {
              store.dispatch(actionSetScenario('3'));

              push.push('/storungenv3');
            }}
          >
            {' '}
            Es liegt eine aktuelle Störung zu Ihrer Reise {<br />} vor.{' '}
            <div
              className={styles.klick}
              onClick={() => {
                store.dispatch(actionSetScenario('3'));

                push.push('/storungenv3');
              }}
            >
              Klicken Sie hier , um mehr zu sehen...
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(HandyV1);
