import { actionSetEnd, actionSetOpenModal } from '../redux/actions/actionMain';
import store from '../redux/store';
import { connect } from 'react-redux';
import gaSendEvent from './googleAnalytics';
import RestAPI from './RestAPI';

/*
Szenario 1 ---> Avasag Icon Geklickt
Szenario 2 ---> Richtige Ticket Gekauft
Szenario 3(Mobile) ---> Nachdem Video geschaut(Störungen video 1)
Szenario 4 ---> Nachdem Video geschaut (9 Euro Ticket FAQ video 1)
*/

var startTime = null;

export const setStartTime = () => {
  startTime = new Date();
};

export const redirectToSoSci = () => {
  const state = store.getState();
  const token = state.main.token;

  const duration = new Date().getTime() - startTime.getTime();

  const data = {
    variant: state.main.variant,
    scenario: state.main.scenario,
    duration,
    number: state.main.param_number,
    successfull: true,
  };

  RestAPI.sendSession(data);

  gaSendEvent(token, JSON.stringify(data));

  setTimeout(() => {
    window.close();
  }, 5000);
  setTimeout(() => {
    store.dispatch(actionSetOpenModal(true));
  }, 2500);
  setTimeout(() => {
    {
      store.dispatch(actionSetEnd(true));
    }
  }, 1000);

  //window.location.href = `https://www.soscisurvey.de/demo/?t=${main.token}`;

  //https://www.soscisurvey.de/test324907/?act=mZ45NTneDsWaewYm1JLaTumf
};
