import _ from 'lodash';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import store from '../../../redux/store';
import {
  actionSetParamNumber,
  actionSetScenario,
  actionSetToken,
  actionSetVariant,
} from '../../../redux/actions/actionMain';
import { reactLocalStorage } from 'reactjs-localstorage';
import { parseQueryString } from '../../../helpers/url.helper';

class Landing extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const query = parseQueryString(window.location.search);

    const token = query.tk ? query.tk : 'defaultToken';
    const num = query.num ? query.num : '';

    const variant = this.props.match.params.variant;
    const scenario = this.props.match.params.scenario;
    let landingModule = variant;
    if (variant === '2') landingModule = 1;
    if (variant === '4') landingModule = 3;

    if (scenario === undefined || variant === undefined) {
      console.log('no valid param: use localStorage data');
    } else {
      store.dispatch(actionSetVariant(variant));
      store.dispatch(actionSetScenario(scenario));
      reactLocalStorage.setObject('avasag-demonstrator', {
        variant,
        scenario,
        token,
      });
    }

    store.dispatch(actionSetToken(token));
    store.dispatch(actionSetParamNumber(num));
    if (scenario === '3') {
      this.props.history.push(`/scenario3`);
    } else {
      this.props.history.push(`/landingv${landingModule}/${variant}`);
    }
  }

  render() {
   
    // dummy rendering
    return <div></div>;
  }
}

export default withRouter(Landing);
