import React, { Component } from 'react';
import styles from './Footer.module.scss';
import {
  Button,
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Segment,
  Visibility,
} from 'semantic-ui-react';
import store from '../../../redux/store';
import { connect } from 'react-redux';
import {
  actionSetLanguage,
  actionSetSignactive,
} from '../../../redux/actions/actionMain';
import {
  actionSetOverlayActive,
  actionSetOverlayPosition,
  actionSetOverlayVideo,
} from '../../../redux/actions/actionOverlay';
import RightClick from '../RightClick/RightClick';
import { withRouter } from 'react-router-dom';
import { redirectToSoSci } from '../../../helpers/sosci.helper';
class FooterV1 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { active, signactive , scenario } = this.props;
    return (
      <div className={styles.footerContainer}>
        <Divider className={styles.divider1} />
        <RightClick>
          <Container className={styles.footer1} textAlign="left">
            <Grid columns={4} divided stackable>
              <Grid.Row>
                <Grid.Column>
                  <Header as="h4" content="Bahn Deutschland" />
                  <List link>
                    <List.Item as="a">Jobs & Karriere</List.Item>
                    <List.Item target="_blank" rel="noopener noreferrer" as="a">
                      Presse
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column>
                  <Header as="h4" content="Über uns" />
                  <List link>
                    <List.Item target="_blank" rel="noopener noreferrer" as="a">
                      AVASAG
                    </List.Item>
                    <List.Item target="_blank" rel="noopener noreferrer" as="a">
                      {' '}
                      Impressum{' '}
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column>
                  <Header as="h4" content="Sprachen" />

                  <List link>
                    <List.Item as="a">
                      <img
                        className={styles.logo1}
                        src={`${process.env.PUBLIC_URL}/assets/images/de_flag.png`}
                      />
                      Deutsch
                    </List.Item>

                    {signactive === false && (
                      <List.Item
                        as="a"
                        onClick={() => {
                          store.dispatch(actionSetSignactive(!signactive));
                          if (scenario === '1') {
                            redirectToSoSci();
                          }}}
                      >
                        <img
                          className={styles.avasagButton}
                          src={`${process.env.PUBLIC_URL}/assets/images/avasag_deaktiv.png`}
                        />
                        Gebärdensprache
                      </List.Item>
                    )}
                    {!signactive === false && (
                      <List.Item
                        as="a"
                 
                        
                      >
                        <img
                          className={styles.avasagButton}
                          src={`${process.env.PUBLIC_URL}/assets/cropped-avasag_logo_orange_512.png`}
                        />
                        Gebärdensprache
                      </List.Item>
                    )}
                  </List>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Header as="h4" content="Kundenservices" />

                  <List link>
                    <List.Item as="a">Hilfe & Kontakt</List.Item>
                    <List.Item as="a">Feedback</List.Item>
                  </List>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </RightClick>
      </div>
    );
  }
}
FooterV1 = connect(
  (state) => ({
    active: state.overlay.active,
    device: state.main.device,
    vm: state.main.vm,
    debugMode: state.main.debugMode,
    signactive: state.main.signactive,
    scenario:state.main.scenario
  }),
  {} // bind account loading action creator
)(FooterV1);
export default withRouter(FooterV1);
