import React from 'react';
import { withRouter } from 'react-router-dom';
import { Breadcrumb, Divider, Header, Icon } from 'semantic-ui-react';
import FooterV3 from '../../../2_molecules/FooterV3/FooterV3';
import HeadV3 from '../../../2_molecules/HeadV3/HeadV3';
import { Component } from 'react';
import styles from './TicketFaq.module.scss';
import {
  actionSetOverlayActive,
  actionSetOverlayVideo,
} from '../../../../redux/actions/actionOverlay';
import { connect } from 'react-redux';
import RightClick from '../../../2_molecules/RightClick/RightClick';
import store from '../../../../redux/store';
import FooterFather from '../../../2_molecules/FooterFather/FooterFather';
import { redirectToSoSci } from '../../../../helpers/sosci.helper';
class TicketFaqV3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active1: false,
      active2: false,
      active3: false,
    };
  }
  render() {
    const { activeIndex, active1, active2, active3 } = this.state;
    const { active, signactive, scenario, onEnd } = this.props;

    return (
      <div className={styles.page}>
        <RightClick>
          <div>
            <HeadV3 />
            <div
              className={
                scenario === '3' ? styles.breadContain_mo : styles.breadContain
              }
            >
              <Breadcrumb>
                <Breadcrumb.Section
                  link
                  onClick={() => this.props.history.push('/landingv3/5')}
                >
                  Startseite
                </Breadcrumb.Section>
                <Icon name="angle right" />
                <Breadcrumb.Section active>
                  9 Euro Ticket FAQ
                </Breadcrumb.Section>
              </Breadcrumb>
            </div>

            {!signactive && (
              <div
                className={scenario === '3' ? styles.master_mo : styles.master}
              >
                <Header size="huge">
                  Allgemeine Informationen zu dem 9€ Ticket
                </Header>
                <Divider />
                <div className={styles.flexKing}>
                  <div
                    className={
                      scenario === '3' ? styles.flexPug1_mo : styles.flexPug1
                    }
                  >
                    <div
                      className={styles.pawnHead}
                      onClick={() => this.setState({ active2: !active2 })}
                    >
                      Wird es eine Verlängerung des 9-Euro-Tickets geben ?{' '}
                      {active2 && <Icon name="caret down" />}
                      {!active2 && <Icon name="caret right" />}
                    </div>
                    {active2 && (
                      <div className={styles.pawnSub}>
                        <div /*Nach diese Video Scenario 4 ist beendet.*/
                          className={signactive && styles.icon}
                        >
                          Das 9 € Ticket wird nicht verlängert. Aber zur Zeit
                          diskutiert die Bundesregierung über ein
                          Nachfolgeticket. Dieses wird allerdings teurer werden.
                          Ein möglicher Preis könnte 49 € pro Monat sein. Aber
                          die Entscheidung liegt steht noch aus!
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <Divider />

                <div className={styles.flexKing}>
                  <div
                    className={
                      scenario === '3' ? styles.flexPug1_mo : styles.flexPug1
                    }
                  >
                    <div
                      className={styles.pawnHead}
                      onClick={() => this.setState({ active1: !active1 })}
                    >
                      Wann gilt das 9€ Ticket ?{' '}
                      {active1 && <Icon name="caret down" />}
                      {!active1 && <Icon name="caret right" />}
                    </div>
                    {active1 && (
                      <div className={styles.pawnSub}>
                        <div className={signactive && styles.icon}>
                          Das 9 Euro-Ticket gilt bundeseinheitlich vom 1.Juni
                          bis zum 31.August 2022, also für die Monate Juni ,
                          Juli und August.
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <Divider />
                <div className={styles.flexKing}>
                  <div
                    className={
                      scenario === '3' ? styles.flexPug1_mo : styles.flexPug1
                    }
                  >
                    <div
                      className={styles.pawnHead}
                      onClick={() => this.setState({ active3: !active3 })}
                    >
                      Kostet das 9-Euro-Ticket pro Monat 9 Euro oder gilt der
                      Preis für drei Monate?{' '}
                      {active3 && <Icon name="caret down" />}
                      {!active3 && <Icon name="caret right" />}
                    </div>
                    {active3 && (
                      <div className={styles.pawnSub}>
                        <div className={signactive && styles.icon}>
                          Der Preis von 9 Euro gilt pro Monat. Wenn Sie das
                          Angebot für alle drei Monate nutzen möchten , zahlen
                          Sie insgesamt 27 Euro.
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {signactive && (
              <div
                className={scenario === '3' ? styles.master_mo : styles.master}
              >
                <Header size="huge">
                  Allgemeine Informationen zu dem 9€ Ticket
                </Header>
                <Divider />
                <div className={styles.flexKing}>
                  <div
                    className={
                      scenario === '3' ? styles.flexPug1_mo : styles.flexPug1
                    }
                  >
                    <div
                      className={styles.pawnHead}
                      onClick={() => this.setState({ active2: !active2 })}
                    >
                      Wird es eine Verlängerung des 9-Euro-Tickets geben ?{' '}
                      <Icon name="caret down" />
                    </div>

                    <div className={styles.pawnSub}>
                      <div
                        className={signactive && styles.icon}
                        onClick={() => {
                          signactive &&
                            store.dispatch(actionSetOverlayActive(true));
                          store.dispatch(
                            actionSetOverlayVideo({
                              name: 'Satz6',
                              onEnd: () => {
                                if (scenario === '4') {
                                  redirectToSoSci();
                                }
                              },
                            })
                          );
                        }}
                      >
                        Das 9 € Ticket wird nicht verlängert. Aber zur Zeit
                        diskutiert die Bundesregierung über ein Nachfolgeticket.
                        Dieses wird allerdings teurer werden. Ein möglicher
                        Preis könnte 49 € pro Monat sein. Aber die Entscheidung
                        liegt steht noch aus!
                      </div>
                    </div>
                  </div>
                </div>

                <Divider />
                <div className={styles.flexKing}>
                  <div
                    className={
                      scenario === '3' ? styles.flexPug1_mo : styles.flexPug1
                    }
                  >
                    <div
                      className={styles.pawnHead}
                      onClick={() => this.setState({ active1: !active1 })}
                    >
                      Wann gilt das 9€ Ticket ? <Icon name="caret down" />
                    </div>

                    <div className={styles.pawnSub}>
                      <div
                        className={signactive && styles.icon}
                        onClick={() => {
                          signactive &&
                            store.dispatch(actionSetOverlayActive(true));
                          store.dispatch(actionSetOverlayVideo('Satz11'));
                        }}
                      >
                        Das 9 Euro-Ticket gilt bundeseinheitlich vom 1.Juni bis
                        zum 31.August 2022, also für die Monate Juni , Juli und
                        August.
                      </div>
                    </div>
                  </div>
                </div>

                <Divider />
                <div className={styles.flexKing}>
                  <div
                    className={
                      scenario === '3' ? styles.flexPug1_mo : styles.flexPug1
                    }
                  >
                    <div
                      className={styles.pawnHead}
                      onClick={() => this.setState({ active3: !active3 })}
                    >
                      Kostet das 9-Euro-Ticket pro Monat 9 Euro oder gilt der
                      Preis für drei Monate? <Icon name="caret down" />
                    </div>

                    <div className={styles.pawnSub}>
                      <div
                        className={signactive && styles.icon}
                        onClick={() => {
                          signactive &&
                            store.dispatch(actionSetOverlayActive(true));
                          store.dispatch(actionSetOverlayVideo('Satz11'));
                        }}
                      >
                        Der Preis von 9 Euro gilt pro Monat. Wenn Sie das
                        Angebot für alle drei Monate nutzen möchten , zahlen Sie
                        insgesamt 27 Euro.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <FooterFather scenario={scenario} />
          </div>
        </RightClick>
      </div>
    );
  }
}
TicketFaqV3 = connect(
  (state) => ({
    bas: state.main.bas,
    signactive: state.main.signactive,
    active: state.overlay.active,
    device: state.main.device,
    vm: state.main.vm,
    scenario: state.main.scenario,
    debugMode: state.main.debugMode,
  }),
  {} // bind account loading action creator
)(TicketFaqV3);
export default withRouter(TicketFaqV3);
