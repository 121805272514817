import React, { Component } from 'react';
import { Breadcrumb, Grid, Header, Segment, Icon } from 'semantic-ui-react';
import styles from './BahnUmwelt.module.scss';
import FooterFather from '../../../2_molecules/FooterFather/FooterFather';
import FooterV3 from '../../../2_molecules/FooterV3/FooterV3';
import HeadV3 from '../../../2_molecules/HeadV3/HeadV3';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { actionSetOverlayVideo } from '../../../../redux/actions/actionOverlay';
import RightClick from '../../../2_molecules/RightClick/RightClick';
import store from '../../../../redux/store';
import { actionSetOverlayActive } from '../../../../redux/actions/actionOverlay';

class BahnUmweltV3 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { bas, signactive, active , scenario } = this.props;
    return (
      <div className={styles.page}>
        <RightClick>
          <div>
            <HeadV3 />
            <div className={scenario === "3" ? styles.breadContain_mo : styles.breadContain}>
              <Breadcrumb>
                <Breadcrumb.Section
                  link
                  onClick={() => this.props.history.push('/landingv3/5')}
                >
                  Startseite
                </Breadcrumb.Section>
                <Icon name="angle right" />
                <Breadcrumb.Section active>Bahn & Umwelt</Breadcrumb.Section>
              </Breadcrumb>
            </div>
            <div className={styles.landingContainer}>
              <div>
                <div className={scenario === "3" ? styles.landBum_mo : styles.landBum}>
                  <div className={scenario === "3" ?styles.landBum1_mo : styles.landBum1}>
                    <div className={styles.bumHead}>
                      Energiesparen mit moderner Technik
                    </div>
                    <div className={styles.bumText}>
                    <div
                        className={signactive && styles.icon}
                        onClick={() => { signactive && 
                          store.dispatch(actionSetOverlayActive(true));
                          store.dispatch(actionSetOverlayVideo('Satz11'));
                        }}
                      >
                      Fahrassistenzsysteme unterstützen beim energiesparenden
                      Fahren. Sie zeigen zum Beispiel anhand der
                      Streckentopografie und des Fahrplans, wo unsere
                      Lokführer:innen frühzeitig den Antrieb abschalten und den
                      Zug ausrollen lassen können. Im Fern- und Güterverkehr
                      sind solche Assistenzsysteme bereits im Einsatz. Auch im
                      Regionalverkehr verfügen die elektrischen Züge über
                      Energiezähler. Jetzt wird die Technik auch in Dieselzüge
                      im Regionalverkehr eingebaut.
                      </div></div>
                  </div>
                </div>
                <div className={scenario === "3" ? styles.landBum_mo : styles.landBum}>
                  <div className={scenario === "3" ?styles.landBum1_mo :styles. landBum1}>
                    <div className={styles.bumHead}>
                      Die Grüne Transformation der Bahn Deutschland
                    </div>
                    <div className={styles.bumText}>
                      <div
                        className={signactive && styles.icon}
                        onClick={() => { signactive && 
                          store.dispatch(actionSetOverlayActive(true));
                          store.dispatch(actionSetOverlayVideo('Satz11'));
                        }}
                      >
                        Heißere Sommer, mildere Winter und flutartige
                        Regenfälle: Um zukünftigen Generationen einen
                        lebenswerten Planeten zu hinterlassen, müssen wir jetzt
                        handeln. Wir als Bahn Deutschland stellen uns dieser
                        Aufgabe und nehmen unsere Verantwortung für die Umwelt
                        und unsere Gesellschaft wahr. Dies haben wir in unserer
                        Konzernstrategie "Starke Schiene" verankert und setzen
                        es mit der Grünen Transformation der BD um.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <FooterFather scenario={scenario} />
          </div>
        </RightClick>
      </div>
    );
  }
}

BahnUmweltV3 = connect(
  (state) => ({
    bas: state.main.bas,
    signactive: state.main.signactive,
    active: state.overlay.active,
    device: state.main.device,
    vm: state.main.vm,
    debugMode: state.main.debugMode,
    scenario: state.main.scenario,
  }),
  {} // bind account loading action creator
)(BahnUmweltV3);
export default withRouter(BahnUmweltV3);
