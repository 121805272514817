import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.scss";
import { BrowserRouter } from "react-router-dom";
import Main from "./components/4_views/Main/Main";
import store from "./redux/store";
import "semantic-ui-css/semantic.min.css";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename={"/" + process.env.REACT_APP_DEPLOY}>
      <Main />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
