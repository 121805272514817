import _ from 'lodash';
import React, { Component } from 'react';
import {
  Breadcrumb,
  Divider,
  Grid,
  Header,
  Segment,
  Icon,
} from 'semantic-ui-react';
import FooterV3 from '../../../2_molecules/FooterV3/FooterV3';
import HeadV3 from '../../../2_molecules/HeadV3/HeadV3';
import FooterFather from '../../../2_molecules/FooterFather/FooterFather';
import styles from './Ticker.module.scss';
import { actionSetOverlayActive, actionSetOverlayVideo } from '../../../../redux/actions/actionOverlay';
import VideoPlayer2 from '../../../2_molecules/VideoPlayer2/VideoPlayer2';
import store from '../../../../redux/store';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RightClick from '../../../2_molecules/RightClick/RightClick';

class TickerV3 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { } = this.state;
    const { active, signactive, scenario } = this.props;
    return (
      <div className={styles.page}>
                            
        <RightClick>
          <div>
            <HeadV3 />
            <div className={scenario === "3" ? styles.breadContain_mo : styles.breadContain}>
              <Breadcrumb>
                <Breadcrumb.Section
                  onClick={() => this.props.history.push('/landingv3/5')}
                  link
                >
                  Startseite
                </Breadcrumb.Section>
                <Icon name="angle right" />
                <Breadcrumb.Section active>Aktuelles</Breadcrumb.Section>
              </Breadcrumb>{' '}
            </div>

            <div>
              <div className={scenario === "3" ? styles.landBum_mo : styles.landBum}>
                <div className={scenario === "3" ? styles.landBum1_mo : styles.landBum1}>
                  <img
                    className={styles.pict2}
                    src={`${process.env.PUBLIC_URL}/assets/images/treue_bonus.jpg`}
                  />
                </div>
                <div className={scenario === "3" ? styles.landBum2_mo : styles.landBum2}>
                  <div className={styles.bumHead}>September & Oktober</div>
                  <div className={styles.bumText}>
                    <div
                      className={signactive && styles.icon}
                      onClick={() => {
                        signactive &&
                          store.dispatch(actionSetOverlayActive(true));
                        store.dispatch(actionSetOverlayVideo('Satz11'));
                      }}
                    >
                      Abonnenten-Treuebonus : An den Wochenenden und in den
                      Herbstferien Deutschland-weit fahren.Wir sagen DANKE für Ihre
                      Treue! Als Stammkundin bzw. Stammkunde profitieren Sie ab
                      September zwei Monate von unserer "Treuebonus-Aktion".
                    </div>
                  </div>
                </div>
              </div>

              <div className={scenario === "3" ? styles.landBum_mo : styles.landBum}>
                <div className={scenario === "3" ? styles.landBum1_mo : styles.landBum1}>
                  <img
                    className={styles.pict2}
                    src={`${process.env.PUBLIC_URL}/assets/images/9_ticket.jpg`}
                  />
                </div>
                <div className={scenario === "3" ? styles.landBum2_mo : styles.landBum2}>
                  <div

                    className={styles.bumHead}
                  >
                    Die Deutschland-Flatrate für Bus & Bahn
                  </div>
                  <div className={styles.bumText}>
                    <div
                      className={signactive && styles.icon}
                      onClick={() => {
                        signactive &&
                          store.dispatch(actionSetOverlayActive(true));
                        store.dispatch(actionSetOverlayVideo('Satz11'));
                      }}
                    >
                      Verkaufsstart in der BD-App und in den Kundencentern am
                      23.Mai.Das Ticketabo für die 2. Klasse in
                      Deutschland kostet im Juni, Juli und August 2022
                      dann auch nur neun Euro pro Monat.
                    </div>
                  </div>
                </div>
              </div>
              <div className={scenario === "3" ? styles.landBum_mo : styles.landBum}>
                <div className={scenario === "3" ? styles.landBum1_mo : styles.landBum1}>
                  <img
                    className={styles.pict2}
                    src={`${process.env.PUBLIC_URL}/assets/images/umwelt_schutz.jpg`}
                  />
                </div>
                <div className={scenario === "3" ? styles.landBum2_mo : styles.landBum2}>
                  <div
                    className={styles.bumHead}
                  >
                    Klimaschutz und Umweltproblemtik
                  </div>
                  <div className={styles.bumText}>
                    <div
                      className={signactive && styles.icon}
                      onClick={() => {
                        signactive &&
                          store.dispatch(actionSetOverlayActive(true));
                        store.dispatch(actionSetOverlayVideo('Satz11'));
                      }}
                    >
                      Unsere Umwelt liegt uns am Herzen. Seit Juli 2022 wird
                      unsere Dieselflotte mit Biokraftstoff getankt. Damit
                      leisten wir einen kleinen aber konkreten Beitrag gegen den
                      Klimawandel und sparen bei jeder Zugfahrt bis zu 90 % CO₂
                      unterwegs ein.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FooterFather scenario={scenario} />
          </div>
        </RightClick>
    
      </div>
    );
  }
}
TickerV3 = connect(
  (state) => ({
    bas: state.main.bas,
    signactive: state.main.signactive,
    active: state.overlay.active,
    device: state.main.device,
    vm: state.main.vm,
    debugMode: state.main.debugMode,
    scenario: state.main.scenario
  }),
  {} // bind account loading action creator
)(TickerV3);
export default withRouter(TickerV3);
