import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Container,
  Divider,
  Header,
  Icon,
  Image,
  Grid,
  Menu,
  Search,
} from 'semantic-ui-react';
import {
  actionSetScenario,
  actionSetVariant,
} from '../../../redux/actions/actionMain';
import store from '../../../redux/store';
import styles from '../DemoPick/DemoPick.module.scss';

export const DemoPick = () => {
  const history = useHistory();

  return (
    <>
      <div className={styles.host}>
        <div className={styles.flex1}>
          <div className={styles.logoDiv}>
            <img
              className={styles.logoImg}
              src={`${process.env.PUBLIC_URL}/assets/images/avasag_logo_orange.svg`}
            ></img>
          </div>
          <div className={styles.avasagDiv}>
            <Button
              onClick={() => (window.location.href = 'https://avasag.de')}
              color="orange"
            >
              Avasag
            </Button>
          </div>
        </div>

        <Container>
          <Grid columns={3}>
            <Grid.Row>
              <Grid.Column width={16}>
                {' '}
                <div className={styles.head1}>AVASAG Studienergebnisse</div>
              </Grid.Column>
              <Grid.Column width={16}>
                <p>
                  Hier finden Sie die Ergebnisse unserer AVASAG-Untersuchungen.
                </p>
                <p>
                  - Im ersten Schritt wurden die Anforderungen an
                  Übersetzungssysteme für Deutsche Gebärdensprache seitens der
                  verschiedenen Stakeholder (Gehörlose und Unternehmen)
                  ermittelt.{' '}
                </p>
                <Button
                  size="small"
                  target="_blank"
                  href={`${process.env.PUBLIC_URL}/assets/pdfs/anforderungen.pdf`}
                  color="orange"
                >
                  Zu den Ergebnissen
                </Button>
                <br></br>
                <br></br>
                <p>
                  - Die Ergebnisse des Evaluation eines Gebärdensprach-Avatars
                  im Reisekontext sowie die zu Verständlichkeit und
                  Anthropomorphismus von Gebärdensprach-Avataren erfolgte mit
                  Hilfe vieler freiwillig teilnehmenden Gehörlosen im Rahmen
                  eines Demonstrators sowie eine Umfrage. Vielen Dank an alle
                  Teilnehmenden!{' '}
                </p>
                <Button
                  size="small"
                  target="_blank"
                  href={`${process.env.PUBLIC_URL}/assets/pdfs/evaluation.pdf`}
                  color="orange"
                >
                  Zu den Ergebnissen
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <div className={styles.head1}>
                  Gebärdensprach-Avatare im Vergleich – Verständlichkeit und
                  Akzeptanz (2023)
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16 / 3}>
                <video
                  className={styles.video2}
                  src={`${process.env.PUBLIC_URL}/assets/videos/Gloria/DVS-04_Forschung-AVASAG_GLORIA.mp4`}
                  controls
                ></video>
              </Grid.Column>
              <Grid.Column width={16 / 3}>
                {' '}
                <video
                  className={styles.video2}
                  src={`${process.env.PUBLIC_URL}/assets/videos/Livian/DVS-04.mp4`}
                  controls
                ></video>
              </Grid.Column>
              <Grid.Column width={16 / 3}>
                {' '}
                <video
                  className={styles.video2}
                  src={`${process.env.PUBLIC_URL}/assets/videos/Mensch/Umfrage_Verstehen_Satz4_gsf_2023.mp4`}
                  controls
                ></video>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={8}>
                Im Rahmen einer tiefergehenden Untersuchung wurde der umgesetzte
                finale fotorealistische Avatar im Vergleich zu zwei anderen
                Darstellerinnen auf Verständlichkeit und Akzeptanz überprüft.
                Der im Rahmen des AVASAG-Projektes entwickelte Avatar in der
                letzten Ausbaustufe (Livian – mittig) entspricht dem
                erarbeiteten Anforderungskatalog, der Ergebnisse aus
                Überprüfungen im Rahmen einer
                <a
                  target="_blank"
                  href="https://www.bmas.de/DE/Service/Publikationen/Forschungsberichte/fb417.html"
                  color="blue"
                >
                  {' '}
                  Machbarkeitsstudie zur Aschätzung der Nutzungsmöglichkeiten
                  von Gebärdenavataren des BMAS aus 2011
                </a>
                und des{' '}
                <a
                  target="_blank"
                  color="blue"
                  href="https://avatar-bestpractice.univie.ac.at/"
                >
                  Best Practise Leitfadens für den Einsatz von
                  Gebärdensprach-Avataren der Universität Wien 2021
                </a>{' '}
                sowie eigene Erhebungen mit einfließen lässt.
              </Grid.Column>
              <Grid.Column width={8}>
                Neben Livian wurden Gloria, ein in der Darstellung cartooniger
                und hölzern wirkender Avatar, (linkes Video) und als Mensch
                Sonja als Gebärdensprach-Dolmetscherin verglichen. Die
                Ergebnisse aller drei Varianten unterscheiden sich von der
                objektiven Verständlichkeit nur unwesentlich. Sowohl Livian als
                auch Sonja waren in etwa gleich gut zu verstehen. Von der
                subjektiven Akzeptanz allerdings schnitt die menschliche
                Darstellerin besser ab. Schauen Sie sich die Videos an und
                vergleichen Sie selbst.
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column width={8}>
                <div className={styles.head1}>
                  Gebärdensprach-Avatar – Show Case 2 (2022)
                </div>
                Bei dieser Darstellung wurde eine Internetseite für das fiktive
                Bahnunternehmen "Bahn Deutschland" ("BD") entwickelt, welches
                ihre Internetseite für die Gehörlosen-Gemeinschaft digital
                barrierefrei umsetzen möchte. Der Demonstrator wurde für die
                Befragung so umgesetzt, dass die Avatar-Darstellung optisch dem
                neuesten Stand der Technik entspricht und der Avatar realistisch
                und verständlich erscheint. Die Darstellung wurde so gewählt,
                damit eine Vergleichbarkeit mit menschlichen Darstellern möglich
                ist. Darüber hinaus basiert der Demonstrator auf den Ergebnissen
                der Zielgruppenbefragungen gebärdensprachlicher Personen nach
                ihren Nutzungsanforderungen. Diese sollen als
                Handlungsempfehlungen dienen, wie ein Gebärdensprach-Video auf
                digitalen Plattformen idealerweise inklusiv integriert werden
                kann. In dem Demonstrator ist ein Beispiel der zwei
                Darstellungsformen zu finden, die die Einbindung eines
                Gebärdensprach-Videos zeigen.
                <div className={styles.subText2}>
                  {' '}
                  Testen Sie die verschiedenen Darstellungen und nutzen Sie das
                  Gebärdensprach-Video, indem der Avatar den Text übersetzt.
                  Führen Sie z. B. eine Buchung für den 31. März 2023 von Köln
                  nach Essen durch und beachten Sie, dass der Zug die geringste
                  Auslastung hat.
                </div>
              </Grid.Column>
              <Grid.Column width={4}>
                <div className={styles.demo2}>
                  <div className={styles.variantContainer}>
                    <div className={styles.variantItem}>
                      <img
                        onClick={() => {
                          history.push(`/landingv1/1`);
                          store.dispatch(actionSetScenario(5));
                          store.dispatch(actionSetVariant(1));
                        }}
                        className={styles.web0}
                        src={`${process.env.PUBLIC_URL}/assets/images/demo_2-2.png`}
                      />
                      <div className={styles.text1}>
                        Nach der Aktivierung des Gebärdensprach Icons "Hände" in
                        der Kopfzeile der Internetseite werden die
                        gebärdensprachlichen Videos angezeigt.
                      </div>
                    </div>
                    <div className={styles.variantItem}>
                      <Button
                        fluid
                        onClick={() => {
                          history.push(`/landingv1/1`);
                          store.dispatch(actionSetScenario(5));
                          store.dispatch(actionSetVariant(1));
                        }}
                      >
                        Start Variante 1
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column width={4}>
                <div className={styles.demo2}>
                  <div className={styles.variantContainer}>
                    <div className={styles.variantItem}>
                      <img
                        onClick={() => {
                          history.push(`/landingv3/3`);
                          store.dispatch(actionSetScenario(5));
                          store.dispatch(actionSetVariant(3));
                        }}
                        className={styles.web0}
                        src={`${process.env.PUBLIC_URL}/assets/images/Demo_2.png`}
                      />
                      <div className={styles.text1}>
                        Nach der Aktivierung des Gebärdensprach Icons "Hände" in
                        der Kopfzeile der Internetseite werden die
                        gebärdensprachlichen Videos in einem extra Video-Fenster
                        angezeigt, sobald man mit der Maus über die Texte fährt.
                      </div>
                    </div>
                    <div className={styles.variantItem}>
                      <Button
                        fluid
                        onClick={() => {
                          history.push(`/landingv3/3`);
                          store.dispatch(actionSetScenario(5));
                          store.dispatch(actionSetVariant(3));
                        }}
                      >
                        Start Variante 2
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <video
                  className={styles.video}
                  src={`${process.env.PUBLIC_URL}/assets/videos/AVASAG_Website-5_Demo_02_2022_gsf_2023.mp4`}
                  controls
                ></video>
              </Grid.Column>
            </Grid.Row>
            <Divider></Divider>
            <Grid.Row>
              <Grid.Column>
                <div className={styles.head2}>
                  Gebärdensprach-Avatar – Show Case 1 (2021)
                </div>
                In dem webbasierten Demonstrator zeigen wir die erste
                Einsatzmöglichkeit eines Gebärdensprach-Avatars, der eine
                Auswahl aktueller Nachrichten in Echtzeit in DGS übersetzt. In
                dieser Anwendung ist noch keine Mimik enthalten. Die
                Internetseite zeigt außerdem die Möglichkeiten, wie und wo der
                Avatar im Alltag verwendet werden kann, wie z.B. Internetseite,
                Kiosk und Handy. Zusätzlich können Hintergründe und Kleidung
                individuell ausgewählt werden. Durch diese Funktion kann der
                Avatar an die Wünsche und Bedürfnisse der Nutzenden angepasst
                werden (z. B. farblicher Kontrast für sehbehinderte gehörlose
                Menschen). Es ist grundsätzlich auch möglich, den Avatar
                dreidimensional im Raum zu drehen, so dass Verdeckungen von
                Händen sichtbar werden oder die Größe des Avatars individuell
                einzustellen. Diese Funktion ist aber in dieser Anwendung nicht
                freigeschaltet.
                {''}
              </Grid.Column>

              <Grid.Column>
                <div className={styles.demo2}>
                  <img
                    onClick={() =>
                      (window.location.href = 'https://demo.avasag.de')
                    }
                    className={styles.web1}
                    src={`${process.env.PUBLIC_URL}/assets/images/Demo_1.png`}
                  />
                  <Button
                    onClick={() => {
                      window.location.href = 'https://demo.avasag.de';
                    }}
                  >
                    Start Show Case 1
                  </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                {' '}
                <video
                  className={styles.video}
                  src={`${process.env.PUBLIC_URL}/assets/videos/AVASAG_Website-5_Demo_01_2021_gsf_2023.mp4`}
                  controls
                ></video>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    </>
  );
};
