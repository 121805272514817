
export const SET_OVERLAY_VIDEO = 'SET_OVERLAY_VIDEO';

export const actionSetOverlayVideo = (video) => ({
	type: 'SET_OVERLAY_VIDEO',
	video: video,
});

export const actionSetOverlayPosition = (position) => ({
	type: 'SET_OVERLAY_POSITION',
	position: position,
});

export const actionSetOverlayActive = (active) => ({
	type: 'SET_OVERLAY_ACTIVE',
	active: active,
});
