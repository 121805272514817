import React, { Component } from 'react';
import ContextMenu from 'react-jsx-context-menu';
import styles from './RightClick.module.scss';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import store from '../../../redux/store';
import {
  actionSetLanguage,
  actionSetSignactive,
} from '../../../redux/actions/actionMain';
import {
  actionSetOverlayActive,
  actionSetOverlayPosition,
  actionSetOverlayVideo,
} from '../../../redux/actions/actionOverlay';
import { withRouter } from 'react-router-dom';
class RightClick extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { signactive, children, active } = this.props;
    return (
      <ContextMenu
        menu={
          <div className={styles.menu1}>
            {/* <Button.Group vertical>
              {signactive === false && (
                <Button
                  onClick={() => {
                    store.dispatch(actionSetSignactive(!signactive));
                    store.dispatch(actionSetLanguage('gs'));
                  }}
                >
                  {' '}
                  <img
                    className={styles.avasagButton2}
                    src={`${process.env.PUBLIC_URL}/assets/images/avasag_deaktiv.png`}
                  />
                  {' Gebärdensprach-Mod aktivieren  '}
                </Button>
              )}
              {signactive === true && (
                <Button
                  onClick={() => {
                    store.dispatch(actionSetSignactive(!signactive));
                    store.dispatch(actionSetLanguage('de'));
                  }}
                >
                  {' '}
                  <img
                    className={styles.avasagButton2}
                    src={`${process.env.PUBLIC_URL}/assets/cropped-avasag_logo_orange_512.png`}
                  />{' '}
                  Gebärdensprach-Mod deaktivieren{' '}
                </Button>
              )}
            </Button.Group> */}
          </div>
        }
      >
        {children}
      </ContextMenu>
    );
  }
}
RightClick = connect(
  (state) => ({
    active: state.overlay.active,
    device: state.main.device,
    vm: state.main.vm,
    debugMode: state.main.debugMode,
    signactive: state.main.signactive,
  }),
  {} // bind account loading action creator
)(RightClick);
export default withRouter(RightClick);
