import React, { Component } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import styles from './VideoPlayer2.module.scss';
import SubTitles from '../SubTitles/SubTitles';
import { data } from './VideoPlayer.data';
import './Slider.css';
import ReactSlider from 'react-slider';

class VideoPlayer2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onEnd: null,
      videoData: null,
      playing: false,
      visible: false,
      subtitleText: null,
      played: 0,
    };

    setTimeout(() => {
      this.init(this.props.index, this.props.video);
    }, 200);
  }

  init(index, video) {
    let onEnd = null;

    let videoData = data.find((entry) => entry.index === index);
    if (!videoData && video) {
      videoData = data.find((entry) => entry.index === video);
      if (!videoData && video.name) {
        videoData = data.find((entry) => {
          return entry.index === video.name;
        });
      }
    }

    if (video && video.onEnd) {
      onEnd = video.onEnd;
    }

    let subtitleText = null;
    const subtitleActive =
      this.props.variant === '2' || this.props.variant === '4';
    if (subtitleActive) {
      subtitleText = videoData.subtitle
        ? videoData.subtitle
        : videoData.subtitles.join();
    }

    this.setState({
      onEnd,
      videoData,
      playing: false,
      visible: false,
      subtitleText,
      played: 0,
    });
    if (this.props.variant === "3" || this.props.variant === "4") {
      this.setState({
        playing: true
      })
    }
  }

  /*
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.video !== this.state.videoData.index
    ) {
      console.log('# change video');
//      this.init(nextProps.video,)
    }
  }
*/
  componentDidMount() {
    
  }

  componentWillUnmount() {}

  ref = (player) => {
    this.player = player;
  };

  render() {
    const { playing, visible, size, subtitleText, videoData, played , } =
      this.state;
    const { onEnd, onClose, onSize  } = this.props;

    // if (!active) return <div></div>;

    const height = size;
    const width = size;

    let url = videoData?`${process.env.PUBLIC_URL}/assets/videos/${videoData.fileName}`:null;

    return (
      <div
        className={styles.main}
        style={{ width: `${width}%`, height: `${height}%` }}
      >
        {
          <ReactPlayer
            ref={this.ref}
            className="react-player"
            url={url}
            width="100%"
            height="100%"
            loop={false}
            playing={playing &&  url  ? true : false}
            progressInterval={1}
            onEnded={() => {
              if (onEnd) {
                onEnd();
              } else if (this.state.onEnd) {
                this.state.onEnd();
              }
            }}
            onMouseEnter={(e) => {
              this.setState({
                visible: true,
              });
            }}
            onMouseLeave={(e) => {
              this.setState({ visible: false });
            }}
            onProgress={(changeState) => {
              this.setState({ played: changeState.played * 100000 });
              console.log(played);
            }}
          />
        }

        {subtitleText && videoData && (
          <div className={styles.subtitle}>
            <div>{subtitleText}</div>
          </div>
        )}
        {false && <div className={styles.subtitleIcon}>UT</div>}
        {onClose && (
          <div className={styles.close}>
            <Button
              size={'mini'}
              icon
              onClick={() => {
                onClose();
              }}
            >
              <Icon color="red" name="close" />
            </Button>
          </div>
        )}

        <div
          className={styles.controlbar}
          onMouseEnter={(e) => {
            this.setState({
              visible: true,
            });
          }}
          onMouseLeave={(e) => {
            this.setState({ visible: false });
          }}
        >
          <div className={visible ? styles.bar : styles.barInvisble}>
            <div className={styles.barLeft}>
              <div className={styles.buttons}>
                {!playing && (
                  <Button
                    size="mini"
                    icon
                    onClick={() => this.setState({ playing: true })}
                  >
                    <Icon name="play" />
                  </Button>
                )}

                {playing && (
                  <Button
                    size="mini"
                    icon
                    onClick={() => this.setState({ playing: false })}
                  >
                    <Icon name="stop" />
                  </Button>
                )}
                <Button
                  size="mini"
                  icon
                  onClick={() => {
                    this.player.seekTo(0);
                  }}
                >
                  <Icon name="undo" />
                </Button>
              </div>
              <div className={styles.seekBar}>
                <ReactSlider
                  className="customSlider"
                  trackClassName="customSlider-track"
                  thumbClassName="customSlider-thumb"
                  min={0}
                  max={100000}
                  value={played}
                  onChange={(value) => {
                    this.setState({ played: value });
                    this.player.seekTo(value / 100000, 'fraction');
                    console.log(value);
                  }}
                />
              </div>
            </div>

            <div className={styles.barRight}>
              {onSize && (
                <Button
                  icon
                  size="mini"
                  onClick={() => {
                    onSize();
                  }}
                >
                  <Icon name="expand" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
VideoPlayer2 = connect(
  (state) => ({
    video: state.overlay.video,
    variant: state.main.variant,
    bigScreen: state.main.bigScreen,
  }),
  {} // bind account loading action creator
)(VideoPlayer2);
// You have to connect() to any reducers that you wish to connect to yourself

export default VideoPlayer2;
