import React from 'react';
import { withRouter } from 'react-router-dom';
import { Breadcrumb, Divider, Header, Icon } from 'semantic-ui-react';
import FooterV1 from '../../../2_molecules/FooterV1/FooterV1';
import HeadV1 from '../../../2_molecules/HeadV1/HeadV1';
import { Component } from 'react';
import styles from './TicketFaq.module.scss';
import VideoPlayer2 from '../../../2_molecules/VideoPlayer2/VideoPlayer2';
import { connect } from 'react-redux';
import RightClick from '../../../2_molecules/RightClick/RightClick';
import { redirectToSoSci } from '../../../../helpers/sosci.helper';
import VideoPlayer from '../../../2_molecules/VideoPlayer2/VideoPlayer';

class TicketFaqV1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active1: false,
      active2: false,
      active3: false,
      activeIndex: -1,
    };
  }
  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };
  render() {
    const { activeIndex, active1, active2, active3 } = this.state;
    const { active, signactive , scenario } = this.props;

    return (
      <div className={styles.page}>
        <RightClick>
          <div>
            <HeadV1 />
            <div className={styles.breadContain}>
              <Breadcrumb>
                <Breadcrumb.Section
                  link
                  onClick={() => this.props.history.push('/landingv1')}
                >
                  Startseite
                </Breadcrumb.Section>
                <Icon name="angle right" />
                <Breadcrumb.Section active>
                  9 Euro Ticket FAQ
                </Breadcrumb.Section>
              </Breadcrumb>
            </div>

            {!signactive && (
              <div className={styles.master}>
                <Header size="huge">
                  Allgemeine Informationen zu dem 9€ Ticket
                </Header>
                <Divider />

                <div className={styles.flexKing}>
                  <div className={styles.flexPug1}>
                    <div
                      className={styles.pawnHead}
                      onClick={() => this.setState({ active2: !active2 })}
                    >
                      Wird es eine Verlängerung des 9-Euro-Tickets geben ?{' '}
                      {active2 && <Icon name="caret down" />}
                      {!active2 && <Icon name="caret right" />}
                    </div>
                    {active2 && (
                      <div className={styles.pawnSub}>
                        Das 9 € Ticket wird nicht verlängert. Aber zur Zeit
                        diskutiert die Bundesregierung über ein Nachfolgeticket.
                        Dieses wird allerdings teurer werden. Ein möglicher
                        Preis könnte 49 € pro Monat sein. Aber die Entscheidung
                        liegt steht noch aus!
                      </div>
                    )}
                  </div>
                </div>
                <Divider />
                <div className={styles.flexKing}>
                  <div className={styles.flexPug1}>
                    <div
                      className={styles.pawnHead}
                      onClick={() => this.setState({ active1: !active1 })}
                    >
                      Wann gilt das 9€ Ticket ? {active1 && <Icon name="caret down" />}
                      {!active1 && <Icon name="caret right" />}
                    </div>
                    {active1 && (
                      <div className={styles.pawnSub}>
                        Das 9 Euro-Ticket gilt bundeseinheitlich vom 1.Juni bis
                        zum 31.August 2022, also für die Monate Juni , Juli und
                        August.
                      </div>
                    )}
                  </div>
                </div>
                <Divider />
                <div className={styles.flexKing}>
                  <div className={styles.flexPug1}>
                    <div
                      className={styles.pawnHead}
                      onClick={() => this.setState({ active3: !active3 })}
                    >
                      Kostet das 9-Euro-Ticket pro Monat 9 Euro oder gilt der
                      Preis für drei Monate? {active3 && <Icon name="caret down" />}
                      {!active3 && <Icon name="caret right" />}
                    </div>
                    {active3 && (
                      <div className={styles.pawnSub}>
                        Der Preis von 9 Euro gilt pro Monat. Wenn Sie das
                        Angebot für alle drei Monate nutzen möchten , zahlen Sie
                        insgesamt 27 Euro.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {signactive && (
              <div className={styles.master}>
                <Header size="huge">
                  Allgemeine Informationen zu dem 9€ Ticket
                </Header>
                <Divider />
                <div className={styles.flexKing}>
                  <div className={styles.flexPawn1}>
                    <div
                      className={styles.pawnHead}
                      onClick={() => this.setState({ active2: !active2 })}
                    >
                      Wird es eine Verlängerung des 9-Euro-Tickets geben ?{' '}
                      <Icon name="caret down" />
                    </div>
                   
                      <div className={styles.pawnSub}>
                        Das 9 € Ticket wird nicht verlängert. Aber zur Zeit
                        diskutiert die Bundesregierung über ein Nachfolgeticket.
                        Dieses wird allerdings teurer werden. Ein möglicher
                        Preis könnte 49 € pro Monat sein. Aber die Entscheidung
                        liegt steht noch aus!
                      </div>
                   
                  </div>
                  <div className={styles.flexPawn2}>
                  
                    {signactive &&  <VideoPlayer  /*Nach diese Video Scenario 4 ist beendet.*/  index={'Satz6'} onEnd={ () => { if (scenario === "4") {
                      
                    }
                      redirectToSoSci();
                    }} />}
                  </div>
                </div>

                <Divider />

                <div className={styles.flexKing}>
                  <div className={styles.flexPawn1}>
                    <div
                      className={styles.pawnHead}
                      onClick={() => this.setState({ active1: !active1 })}
                    >
                      Wann gilt das 9€ Ticket ? <Icon name="caret down" />
                      
                    </div>
                    
                      <div className={styles.pawnSub}>
                        Das 9 Euro-Ticket gilt bundeseinheitlich vom 1.Juni bis
                        zum 31.August 2022, also für die Monate Juni , Juli und
                        August.
                      </div>
                  
                  </div>
                  <div className={styles.flexPawn2}>
                    {signactive && <VideoPlayer index={'Satz11'} />}
                  </div>
                </div>
                <Divider />
                <div className={styles.flexKing}>
                  <div className={styles.flexPawn1}>
                    <div
                      className={styles.pawnHead}
                      onClick={() => this.setState({ active3: !active3 })}
                    >
                      Kostet das 9-Euro-Ticket pro Monat 9 Euro oder gilt der
                      Preis für drei Monate?  <Icon name="caret down" />
                    </div>
                  
                      <div className={styles.pawnSub}>
                        Der Preis von 9 Euro gilt pro Monat. Wenn Sie das
                        Angebot für alle drei Monate nutzen möchten , zahlen Sie
                        insgesamt 27 Euro.
                      </div>
                 
                  </div>
                  <div className={styles.flexPawn2}>
                    {signactive &&  <VideoPlayer index={'Satz11'}  />}
                  </div>
                </div>
              </div>
            )}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <FooterV1 />
          </div>
        </RightClick>
      </div>
    );
  }
}
TicketFaqV1 = connect(
  (state) => ({
    bas: state.main.bas,
    signactive: state.main.signactive,
    active: state.overlay.active,
    device: state.main.device,
    vm: state.main.vm,
    debugMode: state.main.debugMode,
    scenario: state.main.scenario
  }),
  {} // bind account loading action creator
)(TicketFaqV1);
export default withRouter(TicketFaqV1);
