import React, { Component } from 'react';
import App from '../App/App';
import { connect } from 'react-redux';
import styles from './Main.module.scss';
import { reactLocalStorage } from 'reactjs-localstorage';
import {
  actionSetApiBase,
  actionSetEnd,
  actionSetOpenModal,
  actionSetScenario,
  actionSetToken,
  actionSetVariant,
} from '../../../redux/actions/actionMain';
import RestAPI from '../../../RestAPI';
import store from '../../../redux/store';
import { Button, Header, Modal, Rating } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: null,
      rated: false,
    };
  }

  componentDidMount() {
    localStorage.setItem('rated', this.state.rated);

    RestAPI.init().then(() => {
      let settings = null;
      try {
        settings = reactLocalStorage.getObject(
          'avasag-demonstrator',
          { variant: null, scenario: null, token: null },
          true
        );
        store.dispatch(actionSetScenario(settings.scenario));
        store.dispatch(actionSetVariant(settings.variant));
        store.dispatch(actionSetToken(settings.token));
      } catch (e) {}

      store.dispatch(actionSetApiBase(RestAPI.getBase()));
      RestAPI.authStatus().then((result) => {});
    });
  }

  render() {
    const onSendRate = () => {
      if (this.props.variant == "1") {
        localStorage.setItem('Variant-1 Rating', this.state.rating);
        localStorage.setItem('rated', true);
        this.setState({ rated: true });
        store.dispatch(actionSetEnd(false));
      }
      if (this.props.variant == '3') {
        localStorage.setItem('Variant-2 Rating', this.state.rating);
        localStorage.setItem('rated', true);
        this.setState({ rated: true });
        store.dispatch(actionSetEnd(false));
      } else {
        console.log('no variant found');
      }
    };
    const onCheckOtherVariant = () => {
      if (this.props.variant == '1') {
        this.props.history.push('/landingv3/3');
        store.dispatch(actionSetVariant('3'));
        store.dispatch(actionSetOpenModal(false));
      }
      if (this.props.variant == '3') {
        this.props.history.push('/landingv1/1');
        store.dispatch(actionSetVariant('1'));
        store.dispatch(actionSetOpenModal(false));
      } else {
        console.log('no variant found');
      }
    };
    return (
      <div>
        {this.props.scenario !== '3' && (
          <div>
            {this.props.device && (
              <div className={styles.mbDetect}>
                Diese Version von der Website ist nicht für Handys geeignet.
              </div>
            )}
            {this.props.end && (
              <>
                <div className={styles.wrapperContainer}>
                  <div className={styles.trapper}>
                    <svg className={styles.checkmark} viewBox="0 0 52 52">
                      <circle
                        className={styles.checkmark__circle}
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none"
                      />
                      <path
                        className={styles.checkmark__check}
                        fill="none"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      />
                    </svg>
                  </div>
                  <div className={styles.wrapper} />
                </div>
                <div className={styles.modal}>
                  <Modal style={{ width: '500px' }} open={this.props.openModal}>
                    {' '}
                    <Header icon="sign language" content="Ihre Bewertung" />
                    <Modal.Content>
                      <p>Wie finden Sie die Einbindung des Avatars in dieser Variante?</p>
                      <Rating
                        clearable
                        maxRating={5}
                        defaultRating={3}
                        onRate={(evt) =>
                          this.setState({ rating: evt.target.ariaPosInSet })
                        }
                      />
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                      color="orange"
                        onClick={() => {
                          onSendRate();
                          window.location.href = 'https://avasag.de';
                          store.dispatch(actionSetOpenModal(false));
                        }}
                      >
                        Zurück zur Website
                      </Button>
                      {this.state.rated == false ? (
                        <Button
                          color="green"
                          onClick={() => {
                            onSendRate();
                            onCheckOtherVariant();
                          }}
                        >
                          Teste die Variante {this.props.variant == "1" ? "2" : "1"} 
                        </Button>
                      ) : null}
                    </Modal.Actions>
                  </Modal>
                </div>
              </>
            )}
            <App />
          </div>
        )}
        {this.props.scenario === '3' && (
          <div>
            {this.props.end && (
              <>
                <div className={styles.wrapperContainer}>
                  <div className={styles.trapper}>
                    <svg className={styles.checkmark} viewBox="0 0 52 52">
                      <circle
                        className={styles.checkmark__circle}
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none"
                      />
                      <path
                        className={styles.checkmark__check}
                        fill="none"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      />
                    </svg>
                  </div>
                  <div className={styles.wrapper} />
                </div>
              </>
            )}
            <img
              className={styles.Background}
              src={`${process.env.PUBLIC_URL}/assets/images/background_1.jpg`}
            />
            <img
              className={styles.Iphone}
              src={`${process.env.PUBLIC_URL}/assets/images/handwithphone.png`}
            />
            {this.props.device && (
              <div className={styles.mbDetect}>
                Diese Version von der Website ist nicht für Handys geeignet.
              </div>
            )}
            <div className={styles.website}>
              <App />
            </div>
          </div>
        )}
      </div>
    );
  }
}
Main = connect(
  (state) => ({
    variant: state.main.variant,
    scenario: state.main.scenario,
    end: state.main.end,
    device: state.main.device,
    openModal: state.main.openModal,
  }),
  {} // bind account loading action creator
)(Main);
export default withRouter(Main);
