const initialState = {
  video: null,
  videoTrigger: 1,
  position: null,
  active: false,
};

const reducerOverlay = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_OVERLAY_VIDEO':
      return {
        ...state,
        video: action.video,
        videoTrigger: state.videoTrigger + 1,
      };
    case 'SET_OVERLAY_POSITION':
      return {
        ...state,
        position: action.position,
      };
    case 'SET_OVERLAY_ACTIVE':
      return {
        ...state,
        active: action.active,
      };
    default:
      return state;
  }
};

export default reducerOverlay;
