export const data = [
  {
    index: 'Satz1',
    fileName: null,
    subtitle:
      'Hallo! Ich bin ein Gebärdensprach-Avatar der nächsten Generation. Zukünftig werde ich Texte in digitalen Anwendungen und Webseiten in Gebärdensprache übersetzen!',
  },
  {
    index: 'Satz2',
    fileName: "Satz_2.mp4",
    subtitle:
      "satz_2.vtt",
  },
  {
    index: 'Satz3',
    fileName: null,
    subtitle:
      'Auf Deiner Reise nach Essen musst Du zweimal umsteigen. Deine Umstiege sind in Düsseldorf und Duisburg.',
  },
  {
    index: 'Satz4',
    fileName: "Satz_4.mp4",
    subtitle:
      "satz_4.vtt",
  },
  {
    index: 'Satz5',
    fileName: null,
    subtitle: 'Für diese Fahrt kannst du das günstige 9 € Ticket nutzen.',
  },
  {
    index: 'Satz6',
    fileName: "Satz_6.mp4",
    subtitle:
      "satz_6.vtt",
  },
  {
    index: 'Satz7',
    fileName: null,
    subtitle:
      'Ich bin ein Gebärdensprach-Avatar und möchte dir dabei helfen, den Alltag für dich einfacher zu gestalten und Barrieren abzubauen.',
  },
  {
    index: 'Satz8',
    fileName: null,
    subtitle:
      'Hallo! Ich begleite dich gerne auf dieser Website und stehe dir jederzeit als Dolmetscherin zur Verfügung.',
  },
  {
    index: 'Satz9',
    fileName: null,
    subtitle: 'Deine Reise dauert voraussichtlich 2 Stunden und 35 Minuten.',
  },
  {
    index: 'Satz10',
    fileName: "Satz_10.mp4",
    subtitle:
      'Wir erwarten im Verlauf Ihrer Reise eine hohe Auslastung. Reservieren Sie bereits jetzt Ihren Wunschplatz.',
  },
  {
    index: 'Satz10b',
    fileName: null,
    subtitle:
      'Wir erwarten im Verlauf Ihrer Reise eine niedrige Auslastung. Reservieren Sie bereits jetzt Ihren Wunschplatz.'
  },
  {
    index: 'Satz11',
    fileName: "Satz_11.mp4",
    subtitle:
      "satz_11.vtt",
  },
  {
    index: 'Satz12',
    fileName: null,
    subtitle:
      'Hallo, bald erscheint hier ein Gebärdensprachvideo mit weiteren Infos. Bitte habt noch etwas Geduld.',
  },
  {
    index: 'Satz13',
    fileName: "Satz_13.mp4",
    subtitle:
    "satz_13.vtt",
  },
  {
    index: 'Satz14',
    fileName: null,
    subtitle:
      'Hallo, erinnert ihr euch noch an diesen Gebärdensprachavatar? ich bin der überarbeitete Gebärdensprachavatar. Dank eurer Rückmeldungen kann ich schon viel besser gebärden! Damit ich noch besser werde und ihr mich noch besser verstehen könnt, brauchen wir weiterhin euer Feedback.',
  },
  {
    index: 'Satz15',
    fileName: null,
    subtitle:
      'Hier findest du die Hauptnavigation mit den Menüpunkten Tickets & Angebot, Aktuelles, Störungen, Infos.',
  },
  {
    index: 'SatzCol5910b3',
    fileName: "Satz_5910b3.mov",
    subtitle: "satz_5910b3.vtt",
  },
  {
    index: 'SatzCol59103',
    fileName: "Satz_59103.mov",
    subtitle: "satz_59103.vtt",
  },
  {
    index: 'test',
    fileName: null,
    subtitle:
      'Test.',
  },
];
