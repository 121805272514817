import _ from 'lodash';
import React, { Component } from 'react';
import {
  Dropdown,
  Grid,
  Icon,
  Image,
  Menu,
  Sticky,
  Visibility,
  Search,
  Divider,
  Header,
  DropdownMenu,
  Modal,
} from 'semantic-ui-react';

import MediaQuery from 'react-responsive';
import OverlayView from '../OverlayView/OverlayView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearcherV3 from '../SearchV3/search';

import store from '../../../redux/store';
import { connect } from 'react-redux';
import styles from './Head.module.scss';

import RightClick from '../RightClick/RightClick';
import {
  actionSetBas,
  actionSetLanguage,
  actionSetSignactive,
} from '../../../redux/actions/actionMain';
import { withRouter } from 'react-router-dom';
import { redirectToSoSci } from '../../../helpers/sosci.helper';
import { toHaveStyle } from '@testing-library/jest-dom';

const bottomMenuStyle = {
  backgroundColor: 'white',
  boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.2)',
  border: 0,
};
const overlayStyle = {
  float: 'left',
  margin: '0em 3em 1em 0em',
};

class HeadV3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuFixed: true,
      overlayFixed: false,
      visible: true,
      modal: false,
    };
  }

  render() {
    const { signactive, language, modal, scenario, variant, bought } =
      this.props;
    const { visible } = this.state;
    let landingModule = variant;
    if (variant === '2') landingModule = 1;
    if (variant === '4') landingModule = 3;
    const mobile = scenario === '3' && (variant === '3' || variant === '4');
    const limit = mobile ? 10000 : 900;
    return (
      <div className={styles.page}>
        <RightClick>
          <div>
            {/* Heads up, style below isn't necessary for correct work of example, simply our docs defines other
            background color.
          */}
            <style>
              {`
          html, body {
            background: #fff;
          }
        `}
            </style>
            <MediaQuery minWidth={limit}>
              <div className={styles.divMenu}>
                <Sticky>
                  <Menu
                    borderless
                    attached
                    style={bottomMenuStyle}
                    stackable
                    size="large"
                  >
                    <Menu.Item
                      header
                      onClick={() =>
                        this.props.history.push(
                          `/landingv${landingModule}/${scenario}`
                        )
                      }
                      as="a"
                    >
                      <Image
                        size="tiny"
                        src={`${process.env.PUBLIC_URL}/assets/images/blue_logo.png`}
                      />
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => this.props.history.push('/verbindungsv3')}
                      as="a"
                    >
                      <div className={styles.menuIcon}>
                        <Icon size="large" name="train" />
                      </div>

                      <div className={styles.menuItem}>Verbindungssuche</div>
                    </Menu.Item>

                    <Dropdown
                      item
                      text={
                        <div className={styles.menuItem}>
                          <Icon size="large" name="newspaper" /> Aktuelles
                        </div>
                      }
                    >
                      <Dropdown.Menu horizontal>
                        <Dropdown.Item
                          onClick={() =>
                            this.props.history.push('/aktuellesv3')
                          }
                          as="a"
                        >
                          <Icon name="newspaper" />
                          Aktuelle Themen
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            this.props.history.push('/storungenv3')
                          }
                          as="a"
                        >
                          <Icon name="warning sign" />
                          Störungen
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown
                      item
                      text={
                        <span className={styles.menuItem}>
                          <Icon size="large" name="info circle" />
                          Infos
                        </span>
                      }
                    >
                      <Dropdown.Menu horizontal>
                        <Dropdown.Item
                          onClick={() => this.props.history.push('/9faqv3')}
                          as="a"
                        >
                          <Icon name="question circle" />9 Euro Ticket FAQ
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            this.props.history.push('/umweltv3');
                            store.dispatch(actionSetBas('umwelt'));
                          }}
                          as="a"
                        >
                          <Icon name="envira" />
                          Bahn & Umwelt
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Menu.Item>
                      <SearcherV3 />
                    </Menu.Item>
                    {signactive && (
                      <Menu.Item>
                        <Modal
                          closeIcon
                          open={modal}
                          trigger={
                            <Icon
                              color="orange"
                              size="large"
                              name="question"
                            ></Icon>
                          }
                          onClose={() => this.setState({ modal: false })}
                          onOpen={() => this.setState({ modal: true })}
                        >
                          <Modal.Content>
                            <Image
                              size="huge"
                              src={`${process.env.PUBLIC_URL}/Gif.gif`}
                            />
                          </Modal.Content>
                        </Modal>
                      </Menu.Item>
                    )}
                    <Menu.Item position="right">
                      {signactive && (
                        <img
                          onClick={() => {
                            store.dispatch(actionSetSignactive(!signactive));
                          }}
                          className={styles.avasagButton}
                          src={`${process.env.PUBLIC_URL}/assets/cropped-avasag_logo_orange_512.png`}
                        />
                      )}
                      {!signactive && (
                        <img
                          onClick={() => {
                            store.dispatch(actionSetSignactive(!signactive));
                          }}
                          className={styles.avasagButton}
                          src={`${process.env.PUBLIC_URL}/assets/images/avasag_deaktiv.png`}
                        />
                      )}
                    </Menu.Item>
                    <Dropdown item text={'Deutsch'}>
                      <Dropdown.Menu>
                        <Dropdown.Item>Englisch</Dropdown.Item>
                        <Dropdown.Item>Französisch</Dropdown.Item>
                        <Dropdown.Item>Spanisch</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Menu>
                </Sticky>
              </div>

              <div className={styles.backgroundImageContainer}>
                <img
                  className={styles.backgroundImage}
                  src={`${process.env.PUBLIC_URL}/assets/images/homepage_7.jpg`}
                />
              </div>
            </MediaQuery>
            <MediaQuery maxWidth={limit + 1}>
              <div className={styles.divMenu_mo}>
                <Menu borderless attached style={bottomMenuStyle} size="large">
                  <Dropdown item text={<Icon size="big" name="bars" />}>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          this.props.history.push('/verbindungsv3');
                        }}
                      >
                        <Icon name="train" /> {bought === false && 'Tickets'}
                        {bought === true && 'Meine Tickets'}
                      </Dropdown.Item>
                      {/* <Dropdown.Header > <Icon size='large' name='newspaper' />Aktuelles</Dropdown.Header> */}
                      <Dropdown.Item
                        onClick={() => this.props.history.push('/aktuellesv3')}
                      >
                        <Icon name="newspaper" />
                        Aktuelles Themen
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.props.history.push('/storungenv3')}
                      >
                        <Icon name="warning sign" /> Störungen
                      </Dropdown.Item>
                      {/* <Dropdown.Header >Infos</Dropdown.Header> */}
                      <Dropdown.Item
                        onClick={() => this.props.history.push('/9faqv3')}
                      >
                        <Icon name="question circle" /> 9€ Ticket FAQ
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => this.props.history.push('/umweltv3')}
                      >
                        <Icon name="envira" /> Bahn & Umwelt
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Menu.Item
                    position="right"
                    header
                    onClick={() =>
                      this.props.history.push(`/landingv${variant}/${scenario}`)
                    }
                    as="a"
                  >
                    <Image
                      size="tiny"
                      src={`${process.env.PUBLIC_URL}/assets/images/blue_logo.png`}
                    />
                  </Menu.Item>

                  <Menu.Item position="right">
                    {signactive && (
                      <img
                        onClick={() => {
                          store.dispatch(actionSetSignactive(!signactive));
                        }}
                        className={styles.avasagButton}
                        src={`${process.env.PUBLIC_URL}/assets/cropped-avasag_logo_orange_512.png`}
                      />
                    )}
                    {!signactive && (
                      <img
                        onClick={() => {
                          store.dispatch(actionSetSignactive(!signactive));
                        }}
                        className={styles.avasagButton}
                        src={`${process.env.PUBLIC_URL}/assets/images/avasag_deaktiv.png`}
                      />
                    )}
                  </Menu.Item>
                  <Dropdown
                    item
                    text={
                      <img
                        className={styles.logo1}
                        src={`${process.env.PUBLIC_URL}/assets/images/de_flag.png`}
                      />
                    }
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <img
                          className={styles.logo1}
                          src={`${process.env.PUBLIC_URL}/assets/images/uk_flag.jpg`}
                        />
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <img
                          className={styles.logo1}
                          src={`${process.env.PUBLIC_URL}/assets/images/sp_flag.png`}
                        />
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <img
                          className={styles.logo1}
                          src={`${process.env.PUBLIC_URL}/assets/images/fr_flag.png`}
                        />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Menu>
              </div>
            </MediaQuery>
            <div></div>
            <OverlayView />
          </div>
        </RightClick>
      </div>
    );
  }
}

HeadV3 = connect(
  (state) => ({
    active: state.overlay.active,
    device: state.main.device,
    vm: state.main.vm,
    debugMode: state.main.debugMode,
    signactive: state.main.signactive,
    language: state.main.language,
    scenario: state.main.scenario,
    variant: state.main.variant,
    bought: state.main.bought,
  }),
  {} // bind account loading action creator
)(HeadV3);

export default withRouter(HeadV3);
