import React from 'react';
import { Search } from 'semantic-ui-react';
import _ from 'lodash';
import { useHistory, withRouter } from 'react-router-dom';
import { useStore } from 'react-redux';
import store from '../../../redux/store';

const source = [
  {
    title: 'Verbindungssuche',
    path: '/verbindungsv3',
  },
  {
    title: 'Aktuelles Themen',
    path: '/aktuellesv3',
  },
  {
    title: 'Störungen',
    path: '/storungenv3',
  },
  {
    title: '9 Euro Ticket FAQ',
    path: '/9faqv3',
  },
  {
    title: 'Bahn und Umwelt',
    path: '/umweltv3',
  },
];
const initialState = {
  loading: false,
  results: [],
  value: '',
};

function exampleReducer(state, action) {
  switch (action.type) {
    case 'CLEAN_QUERY':
      return initialState;
    case 'START_SEARCH':
      return { ...state, loading: true, value: action.query };
    case 'FINISH_SEARCH':
      return { ...state, loading: false, results: action.results };
    case 'UPDATE_SELECTION':
      return { ...state, value: action.selection };

    default:
      throw new Error();
  }
}
function SearcherV3(props) {
  const [state, dispatch] = React.useReducer(exampleReducer, initialState);
  const { loading, results, value } = state;

  const handleSearchResult = (e, data) => {
    dispatch({ type: 'UPDATE_SELECTION', selection: data.result.title });
    props.history.push(data.result.path);
  };

  const timeoutRef = React.useRef();
  const handleSearchChange = React.useCallback((e, data) => {
    clearTimeout(timeoutRef.current);
    dispatch({ type: 'START_SEARCH', query: data.value });

    timeoutRef.current = setTimeout(() => {
      if (data.value.length === 0) {
        dispatch({ type: 'CLEAN_QUERY' });
        return;
      }

      const re = new RegExp(_.escapeRegExp(data.value), 'i');
      const isMatch = (result) => re.test(result.title);

      dispatch({
        type: 'FINISH_SEARCH',
        results: _.filter(source, isMatch),
      });
    }, 300);
  }, []);
  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);
  return (
    <div>
      <Search
        fluid
        loading={loading}
        placeholder="Search..."
        onResultSelect={handleSearchResult}
        onSearchChange={handleSearchChange}
        results={results}
        value={value}
      />
    </div>
  );
}

export default withRouter(SearcherV3);
