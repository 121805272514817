import React from 'react';
import FooterV3 from '../FooterV3/FooterV3';

import FooterS3 from '../FooterS3/FooterS3';

const FooterFather = (props) => {
  return (
    <div>
      {props.scenario !== '3' && <FooterV3 />}
      {props.scenario === '3' && <FooterS3 />}
    </div>
  );
};
export default FooterFather;
