import React, { Component } from 'react';
import { Button, Header, Form, Grid, Message, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import store from './../../../redux/store';

import styles from './SignDiv.module.scss';
import { actionSetOverlayVideo } from '../../../redux/actions/actionOverlay';

class SignDiv extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { hidden, active, x, y, video, style } = this.props;

    let customStyle = {};
    if (x !== undefined && y !== undefined) {
      customStyle = {
        position: 'absolute',
        top: `${y}%`,
        left: `${x}%`,
      };
    } else if (style) {
      customStyle = style;
    }

    return (
      <div
        className={active ? styles.main : styles.nonActive}
        style={customStyle}
      >
        <Button size="mini">
          <img
            onClick={() => {
              store.dispatch(actionSetOverlayVideo(video));
            }}
            className={hidden ? styles.hidden : styles.main}
            src={`${
              process.env.PUBLIC_URL
            }/assets/${'cropped-avasag_logo_orange_512.png'}`}
          />
        </Button>
      </div>
    );
  }
}
/*
<Tr
_hover={{ backgroundColor: 'gray.50' }}
onMouseEnter={(e) => showButton(e)}
onMouseLeave={(e) => hideButton(e)}
>
{children}
<Td style={{ width: '3em' }}>{display ? elemHover : elem}</Td>
</Tr>
*/

// You have to connect() to any reducers that you wish to connect to yourself
SignDiv = connect(
  (state) => ({
    position: state.overlay.position,
    active: state.overlay.active,
  }),
  {} // bind account loading action creator
)(SignDiv);

export default SignDiv;
