let backendHost;

const hostname = window && window.location && window.location.hostname;
console.log('API host: ', hostname);

const deploy = process.env.REACT_APP_DEPLOY;

if (hostname === 'www.vuppetmaster.de' || hostname === 'vuppetmaster.de') {
  backendHost = 'https://vuppetmaster.de/api_vmadmin';
} else {
  backendHost = 'http://localhost:8443/api_vmadmin';
}

console.log('API_ROOT ', backendHost);

export const API_ROOT = `${backendHost}`;

const post = async (url, data) => {
  return await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(data),
    mode: 'cors',
  })
    .then((response) => {
      if (!response) return null;
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        return response.json();
      } else {
        return response.text().then((text) => ({
          ok: false,
          status: response.status,
          error: text,
        }));
      }
    })
    .catch((e) => {
      return { ok: false, error: e.message };
    });
};

const get = async (url, queryData) => {
  const query = queryData
    ? '?' +
      Object.keys(queryData)
        .map(
          (k) => encodeURIComponent(k) + '=' + encodeURIComponent(queryData[k])
        )
        .join('&')
    : '';
  return await fetch(url + query, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    mode: 'cors',
  })
    .then((response) => {
      if (!response) return null;
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        return response.json();
      } else {
        return response.text().then((text) => ({ ok: false, error: text }));
      }
    })
    .catch((e) => {
      return { ok: false, error: e.message };
    });
};

export const sendSession = async (data) => {
  const result = await post(API_ROOT + '/ga/sendSession', { data });
  return result;
};

export default {
  post: post,
  get,

  sendSession,
};
