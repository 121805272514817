import React from 'react';
import FooterV1 from '../../../2_molecules/FooterV1/FooterV1';
import HeadV1 from '../../../2_molecules/HeadV1/HeadV1';
import styles from '../NotFound404V1/NotFound.module.scss';
import { Button, Header } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import "../../../../../node_modules/video-react/dist/video-react.css";
import 'react-device-emulator/lib/styles/style.css';
import { Player } from 'video-react';




function NotFound404V1(props) {
  const push = useHistory();
  const { variant, scenario } = props;
  let landingModule = variant;
  if (variant === '2') landingModule = 1;
  if (variant === '4') landingModule = 3;
  return (
    <div className={styles.page}>
      <HeadV1 />
      <div className={styles.notFound}>
        <Header size="huge"> 404 Error – Seite nicht gefunden</Header>
        <Header size="huge"> Sorry,</Header>
        <div className={styles.notText}>
          da ist wohl ein Loch im System aufgetreten. Das kann manchmal
          passieren.
          <br />
          Hier kommen Sie züruck zur{' '}
          <Button
            color="blue"
            onClick={() => {
              push.push(`/landingv${landingModule}/${scenario}`);
            }}
          >
            Startseite
          </Button>
    
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <FooterV1 />
    </div>
  );
}
NotFound404V1 = connect(
  (state) => ({
    variant: state.main.variant,
    scenario: state.main.scenario,
  }),
  {} // bind account loading action creator
)(NotFound404V1);
export default NotFound404V1;
