import React, { Component } from 'react';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import store from './../../../redux/store';
import ReactSlider from 'react-slider';
import './Slider.css';
import styles from './OverlayView.module.scss';
import { actionSetOverlayActive } from '../../../redux/actions/actionOverlay';

import VideoPlayer from '../VideoPlayer2/VideoPlayer';
import { redirectToSoSci } from '../../../helpers/sosci.helper';
import { toHaveStyle } from '@testing-library/jest-dom';

const gVideoSizes = [200, 300];
class OverlayView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: gVideoSizes[0],
      x: undefined,
      y: undefined,
    };
  }

  componentDidMount() {
    const handleWindowMouseMove = (event) => {
      {
        !this.props.active &&
          this.setState({
            x: event.pageX,
            y: event.pageY,
          });
      }
    };
    window.addEventListener('mousemove', handleWindowMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleWindowMouseMove);
    };
  }

  render() {
    const {
      size,

      x,
      y,
    } = this.state;
    const { active, video, scenario } = this.props;

    if (!active) return null;

    const height = size;
    const width = Math.floor((size * 16) / 9);
    return (
      <div>
        <Draggable
          bounds={
            scenario === '3' && { top: -75, bottom: 475, left: -15, right: 35 }
          }
          defaultClassName={styles.container}
        >
          <div
            className={styles.main}
            style={{
              width: `${width}px`,
              height: `${height}px`,
              left: `${scenario === '3' ? 15 : x + 200}px`,
              top: `${scenario === '3' ? 150 : y - 75}px`,
            }}
          >
            <VideoPlayer
              onEnd={() => {
                if (scenario === '3' && video.name === 'Satz4') {
                  redirectToSoSci();
                }
                {
                  if (scenario === '4' && video.name === 'Satz6')
                    redirectToSoSci();
                }
                store.dispatch(actionSetOverlayActive(false));
              }}
              onClose={() => {
                store.dispatch(actionSetOverlayActive(false));
              }}
              onSize={() => {
                this.setState({
                  size:
                    this.state.size === gVideoSizes[0]
                      ? gVideoSizes[1]
                      : gVideoSizes[0],
                });
              }}
            />
          </div>
        </Draggable>
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
OverlayView = connect(
  (state) => ({
    position: state.overlay.position,
    video: state.overlay.video,
    videoTrigger: state.overlay.videoTrigger,
    active: state.overlay.active,
    variant: state.main.variant,
    scenario: state.main.scenario,
  }),
  {} // bind account loading action creator
)(OverlayView);

export default OverlayView;
