import _ from 'lodash';
import React, { Component } from 'react';
import { Breadcrumb, Divider, Grid, Header, Segment } from 'semantic-ui-react';
import HeadV1 from '../../../2_molecules/HeadV1/HeadV1';
import { withRouter } from 'react-router-dom';
import FooterV1 from '../../../2_molecules/FooterV1/FooterV1';
import styles from './Landing.module.scss';

import { connect } from 'react-redux';

import VideoPlayer2 from '../../../2_molecules/VideoPlayer2/VideoPlayer2';
import RightClick from '../../../2_molecules/RightClick/RightClick';
import VideoPlayer from '../../../2_molecules/VideoPlayer2/VideoPlayer';

class LandingV1 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const {} = this.state;
    const { active, signactive, bigScreen } = this.props;

    return (
      <div className={styles.page}>
        <HeadV1 />

        <div className={styles.breadContain}>
          <Breadcrumb>
            <Breadcrumb.Section active>Startseite</Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <RightClick>
          <div>
            {!signactive && (
              <div>
                <div className={styles.landBum}>
                  <div className={styles.landBum1}>
                    <img
                      className={styles.pict2}
                      src={`${process.env.PUBLIC_URL}/assets/images/masken_pflicht.jpg`}
                    />
                  </div>
                  <div className={styles.landBum2}>
                    <div className={styles.bumHead}>
                      Weiterhin Maskenpflicht in Bus & Bahn
                    </div>
                    <div className={styles.bumText}>
                      Bitte tragen Sie eine OP- oder FFP2-Maske.Im Nahverkehr in
                      Deutschland gilt weiterhin die Maskenpflicht. Unabhängig
                      vom Inzidenzwert können folgende Masken getragen werden:
                      OP-Masken Atemschutzmasken mit dem Standard FFP2, KN95 und
                      N95.
                    </div>
                  </div>
                </div>
                <div className={styles.landBum}>
                  <div className={styles.landBum1}>
                    <img
                      className={styles.pict2}
                      src={`${process.env.PUBLIC_URL}/assets/images/9_ticket.jpg`}
                    />
                  </div>
                  <div className={styles.landBum2}>
                    <div
                      onClick={() => this.props.history.push('/9faqv1')}
                      className={styles.bumHead}
                    >
                      Die Deutschland-Flatrate für Bus & Bahn
                    </div>
                    <div className={styles.bumText}>
                      Verkaufsstart in der BD-App und in den Kundencentern am
                      23.Mai.Das Ticketabo für die 2. Klasse in Deutschland
                      kostet im Juni, Juli und August 2022 dann auch nur neun
                      Euro pro Monat.
                    </div>
                  </div>
                </div>
              </div>
            )}
            {signactive && (
              <div>
                <div className={styles.landBing}>
                  <div className={styles.landBing1}>
                    <img
                      className={styles.pict2}
                      src={`${process.env.PUBLIC_URL}/assets/images/masken_pflicht.jpg`}
                    />
                  </div>
                  <div className={styles.landBing2}>
                    <div className={styles.bumHead}>
                      Weiterhin Maskenpflicht in Bus & Bahn
                    </div>
                    <div className={styles.bumText}>
                      Bitte tragen Sie eine OP- oder FFP2-Maske.Im Nahverkehr in
                      Deutschland gilt weiterhin die Maskenpflicht. Unabhängig
                      vom Inzidenzwert können folgende Masken getragen werden:
                      OP-Masken Atemschutzmasken mit dem Standard FFP2, KN95 und
                      N95.
                    </div>
                  </div>
                  <div className={styles.landBing3}>
                    <VideoPlayer index={'Satz11'} />
                  </div>
                </div>
                <div className={styles.landBing}>
                  <div className={styles.landBing1}>
                    <img
                      className={styles.pict2}
                      src={`${process.env.PUBLIC_URL}/assets/images/9_ticket.jpg`}
                    />
                  </div>
                  <div className={styles.landBing2}>
                    <div className={styles.bumHead}>
                      Die Deutschland-Flatrate für Bus & Bahn
                    </div>
                    <div className={styles.bumText}>
                      Verkaufsstart in der BD-App und in den Kundencentern am
                      23.Mai.Das Ticketabo für die 2. Klasse in Deutschland
                      kostet im Juni, Juli und August 2022 dann auch nur neun
                      Euro pro Monat.
                    </div>
                  </div>
                  <div className={styles.landBing3}>
                    <VideoPlayer index={'Satz11'} />
                  </div>
                </div>
              </div>
            )}

            <FooterV1 />
          </div>
        </RightClick>
      </div>
    );
  }
}
LandingV1 = connect(
  (state) => ({
    active: state.overlay.active,
    device: state.main.device,
    vm: state.main.vm,
    debugMode: state.main.debugMode,
    signactive: state.main.signactive,
    bigScreen: state.main.bigScreen,
  }),
  {} // bind account loading action creator
)(LandingV1);

export default withRouter(LandingV1);
