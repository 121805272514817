

const initialState = {
    search: true ,
    angebote:true ,
    yesTicket: false,
  };
  
  
  const reducerTicket = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_SEARCH':
        return {
          ...state,
          search: action.search,
        };
      case 'SET_YESTICKET':
        return {
          ...state,
          yesTicket: action.yesTicket,
        };
      default:
        return state;
    }
  };
  
  export default reducerTicket;
  