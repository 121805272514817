import React, { Component } from 'react';
import { Breadcrumb, Grid, Header, Segment, Icon } from 'semantic-ui-react';
import styles from './BahnUmwelt.module.scss';

import FooterV1 from '../../../2_molecules/FooterV1/FooterV1';
import HeadV1 from '../../../2_molecules/HeadV1/HeadV1';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import VideoPlayer2 from '../../../2_molecules/VideoPlayer2/VideoPlayer2';
import RightClick from '../../../2_molecules/RightClick/RightClick';
import VideoPlayer from '../../../2_molecules/VideoPlayer2/VideoPlayer';

class BahnUmweltV1 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { bas, signactive, active } = this.props;
    return (
      <div className={styles.page}>
        <RightClick>
          <div>
            <HeadV1 />
            <div className={styles.breadContain}>
              <Breadcrumb>
                <Breadcrumb.Section
                  link
                  onClick={() => this.props.history.push('/landingv1')}
                >
                  Startseite
                </Breadcrumb.Section>
                <Icon name="angle right" />
                <Breadcrumb.Section active>Bahn & Umwelt</Breadcrumb.Section>
              </Breadcrumb>
            </div>
            <div className={styles.landingContainer}>
              {!signactive && (
                <div>
                  <div className={styles.landBum}>
                    <div className={styles.landBum1}>
                      <div className={styles.bumHead}>
                        Energiesparen mit moderner Technik
                      </div>
                      <div className={styles.bumText}>
                        Fahrassistenzsysteme unterstützen beim energiesparenden
                        Fahren. Sie zeigen zum Beispiel anhand der
                        Streckentopografie und des Fahrplans, wo unsere
                        Lokführer:innen frühzeitig den Antrieb abschalten und
                        den Zug ausrollen lassen können. Im Fern- und
                        Güterverkehr sind solche Assistenzsysteme bereits im
                        Einsatz. Auch im Regionalverkehr verfügen die
                        elektrischen Züge über Energiezähler. Jetzt wird die
                        Technik auch in Dieselzüge im Regionalverkehr eingebaut.
                      </div>
                    </div>
                  </div>
                  <div className={styles.landBum}>
                    <div className={styles.landBum1}>
                      <div className={styles.bumHead}>
                        Die Grüne Transformation der Bahn Deutschland
                      </div>
                      <div className={styles.bumText}>
                        Heißere Sommer, mildere Winter und flutartige
                        Regenfälle: Um zukünftigen Generationen einen
                        lebenswerten Planeten zu hinterlassen, müssen wir jetzt
                        handeln. Wir als Bahn Deutschland stellen uns dieser
                        Aufgabe und nehmen unsere Verantwortung für die Umwelt
                        und unsere Gesellschaft wahr. Dies haben wir in unserer
                        Konzernstrategie "Starke Schiene" verankert und setzen
                        es mit der Grünen Transformation der BD um.
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {signactive && (
                <div>
                  <div className={styles.landBing}>
                    <div className={styles.landBing1}>
                      <div className={styles.bumHead}>
                        Energiesparen mit moderner Technik
                      </div>
                      <div className={styles.bumText}>
                        Fahrassistenzsysteme unterstützen beim energiesparenden
                        Fahren. Sie zeigen zum Beispiel anhand der
                        Streckentopografie und des Fahrplans, wo unsere
                        Lokführer:innen frühzeitig den Antrieb abschalten und
                        den Zug ausrollen lassen können. Im Fern- und
                        Güterverkehr sind solche Assistenzsysteme bereits im
                        Einsatz. Auch im Regionalverkehr verfügen die
                        elektrischen Züge über Energiezähler. Jetzt wird die
                        Technik auch in Dieselzüge im Regionalverkehr eingebaut.
                      </div>
                    </div>
                    <div className={styles.landBing3}>
                      <VideoPlayer index={'Satz11'} />
                    </div>
                  </div>
                  <div className={styles.landBing}>
                    <div className={styles.landBing1}>
                      <div className={styles.bumHead}>
                        Die Grüne Transformation der Bahn Deutschland
                      </div>
                      <div className={styles.bumText}>
                        Heißere Sommer, mildere Winter und flutartige
                        Regenfälle: Um zukünftigen Generationen einen
                        lebenswerten Planeten zu hinterlassen, müssen wir jetzt
                        handeln. Wir als Bahn Deutschland stellen uns dieser
                        Aufgabe und nehmen unsere Verantwortung für die Umwelt
                        und unsere Gesellschaft wahr. Dies haben wir in unserer
                        Konzernstrategie "Starke Schiene" verankert und setzen
                        es mit der Grünen Transformation der BD um.
                      </div>
                    </div>
                    <div className={styles.landBing3}>
                      <VideoPlayer index={'Satz11'} />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <br />
            <br />
            <br />
            <br />
            <FooterV1 />
          </div>
        </RightClick>
      </div>
    );
  }
}

BahnUmweltV1 = connect(
  (state) => ({
    bas: state.main.bas,
    signactive: state.main.signactive,
    active: state.overlay.active,
    device: state.main.device,
    vm: state.main.vm,
    debugMode: state.main.debugMode,
  }),
  {} // bind account loading action creator
)(BahnUmweltV1);
export default withRouter(BahnUmweltV1);
