import ReactGA from 'react-ga';
import store from '../redux/store';

const gaSendEvent = (token, data) => {
  ReactGA.event({
    category: token,
    action: data,
    label: `RandomLabel${Math.random()}`,
  });
};

export default gaSendEvent;
