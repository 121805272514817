const initialState = {
  activePath: '',
  apibase: null,
  debugMode: false,
  device: {},
  vm: null,
  viewport: {},
  xstate: null,
  signactive: false,
  variant: 3,
  bas: 'faq',
  bigScreen: false,
  language: 'de',
  token: 'default',
  param_number: '',
  scenario: 0,
  end:false,
  bought:false,
  openModal:false,
};

const reducerMain = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_VM':
      return {
        ...state,
        vm: action.vm,
      };
    case 'SET_STYLE':
      return {
        ...state,
        style: action.style,
      };
    case 'SET_APIBASE':
      return {
        ...state,
        apibase: action.apibase,
      };
    case 'SET_DEVICE':
      return {
        ...state,
        device: action.device,
      };
    case 'SET_PATH':
      return {
        ...state,
        activePath: action.activePath,
      };
    case 'SET_DEBUG_MODE':
      return {
        ...state,
        debugMode: action.debugMode,
      };
    case 'SET_VIEWPORT':
      return {
        ...state,
        viewport: action.viewport,
      };
    case 'SET_XSTATE':
      return {
        ...state,
        xstate: action.xstate,
      };
    case 'SET_SIGNACTIVE':
      return {
        ...state,
        signactive: action.signactive,
      };
    case 'SET_BAS':
      return {
        ...state,
        bas: action.bas,
      };
    case 'SET_VARIANT':
      return {
        ...state,
        variant: action.variant,
      };
    case 'SET_SCENARIO':
      return {
        ...state,
        scenario: action.scenario,
      };
      case 'SET_BOUGHT':
        return{
          ...state,
          bought:action.bought,
        }
    case 'SET_TOKEN':
      return {
        ...state,
        token: action.token,
      };
    case 'SET_PARAM_NUMBER':
      return {
        ...state,
        param_number: action.param_number,
      };
    case 'SET_LANGUAGE':
      return {
        ...state,
        language: action.language,
      };
      case 'SET_END' : 
      return {
        ...state,
        end: action.end,
      };
      case 'SET_OPENMODAL' : 
      return {
        ...state,
        openModal: action.openModal,
      };
    case 'SET_BIGSCREEN':
      return {
        ...state,
        bigScreen: action.bigScreen,
      };
    default:
      return state;
  }
};

export default reducerMain;
