import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Breadcrumb, Button, Icon } from 'semantic-ui-react';
import FooterV3 from '../../../../2_molecules/FooterV3/FooterV3';
import HeadV3 from '../../../../2_molecules/HeadV3/HeadV3';
import styles from './NoTicketsV3.module.scss';
import { connect } from 'react-redux';
import store from '../../../../../redux/store';
import {
  actionSetOverlayVideo,
  actionSetOverlayActive,
} from '../../../../../redux/actions/actionOverlay';
import FooterFather from '../../../../2_molecules/FooterFather/FooterFather';
class NoTicketsV3 extends Component {
  render() {
    const { signactive, active, scenario } = this.props;
    return (
      <div className={styles.page}>
        <HeadV3 />
        <div
          className={
            scenario === '3' ? styles.breadContain_mo : styles.breadContain
          }
        >
          <Breadcrumb>
            <Breadcrumb.Section
              onClick={() => this.props.history.push('/landingv3')}
              link
            >
              Startseite
            </Breadcrumb.Section>
            <Icon name="angle right" />
            <Breadcrumb.Section
              link
              onClick={() => this.props.history.push('/verbindungsv3')}
            >
              Verbindungssuche
            </Breadcrumb.Section>
            <Icon name="angle right" />
            <Breadcrumb.Section>No Tickets</Breadcrumb.Section>
          </Breadcrumb>{' '}
        </div>

        <div
          className={
            scenario === '3'
              ? styles.ticketContainer_mo
              : styles.ticketContainer
          }
        >
          <div className={styles.ticketBing1}>
            <lbl className={styles.noTicket}>
              <div
                className={signactive && styles.icon}
                onClick={() => {
                  signactive && store.dispatch(actionSetOverlayActive(true));
                  store.dispatch(actionSetOverlayVideo('Satz2'));
                }}
              >
                Leider gibt es unter Deinen Angaben keinen passenden Zug.
              </div>
            </lbl>
          </div>

          <div className={styles.ticketBing2}>
            <Button
              basic
              floated="right"
              onClick={() => this.props.history.push('/verbindungsv3')}
            >
              Zurück zur Suche
            </Button>
          </div>
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <br />
        <br />
        <br />
        <FooterFather scenario={scenario} />
      </div>
    );
  }
}
NoTicketsV3 = connect(
  (state) => ({
    search: state.ticket.search,
    signactive: state.main.signactive,
    active: state.overlay.active,
    scenario: state.main.scenario,
  }),
  {} // bind account loading action creator
)(NoTicketsV3);
export default withRouter(NoTicketsV3);
