import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Breadcrumb,
  Divider,
  Grid,
  GridColumn,
  Header,
  Icon,
  Segment,
} from 'semantic-ui-react';
import FooterV1 from '../../../2_molecules/FooterV1/FooterV1';
import HeadV1 from '../../../2_molecules/HeadV1/HeadV1';
import styles from './Disturbances.module.scss';
import TextSelector from 'text-selection-react';
import {
  actionSetOverlayActive,
  actionSetOverlayVideo,
} from '../../../../redux/actions/actionOverlay';
import store from '../../../../redux/store';
import { connect } from 'react-redux';
import SignDiv from '../../../2_molecules/SignDiv/SignDiv';
import RightClick from '../../../2_molecules/RightClick/RightClick';
import VideoPlayer2 from '../../../2_molecules/VideoPlayer2/VideoPlayer2';
import VideoPlayer from '../../../2_molecules/VideoPlayer2/VideoPlayer';
import { redirectToSoSci } from '../../../../helpers/sosci.helper';

class DisturbancesV1 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { active, signactive ,scenario } = this.props;
    return (
      <div>
        <RightClick>
          <div className={styles.page}>
            <HeadV1 />
            <div className={styles.breadContain}>
              <Breadcrumb>
                <Breadcrumb.Section
                  link
                  onClick={() => this.props.history.push('/landingv1')}
                >
                  Startseite
                </Breadcrumb.Section>
                <Icon name="angle right" />
                <Breadcrumb.Section active>Störungen</Breadcrumb.Section>
              </Breadcrumb>
            </div>
            <div className={styles.master}>
              <div className={styles.flexKing}>
                <div className={styles.flexPawn1}>
                  <Icon size="big" name="info sign" />
                </div>
                <div className={styles.flexPawn2}>
                  <div className={styles.headPawn}>ICE 1557 von Mainz</div>
                  <div className={styles.subPawn}>
                    Ankunft: 8:41 Uhr - fällt heute aus.
                  </div>
                  <div className={styles.subPawn}>
                    Grund dafür ist eine technische Störung am Zug.
                  </div>
                </div>
                <div className={styles.flexPawn3}>
                  {signactive && <VideoPlayer index={'Satz4'}   />}
                </div>
              </div>
              <Divider />
              <div className={styles.flexKing}>
                <div className={styles.flexPawn1}>
                  <Icon size="big" name="info sign" />
                </div>
                <div className={styles.flexPawn2}>
                  <div className={styles.headPawn}> ICE 651 von Köln</div>
                  <div className={styles.subPawn}>
                    Ankunft: 17:20 - fällt heute aus.
                  </div>
                  <div className={styles.subPawn}>
                    Grund dafür ist eine technische Störung am Zug.
                  </div>
                </div>
                <div className={styles.flexPawn3}>
                  {signactive && <VideoPlayer index={'Satz11'} />}
                </div>
              </div>
              <Divider />
              <div className={styles.flexKing}>
                <div className={styles.flexPawn1}>
                  <Icon size="big" name="info sign" />
                </div>
                <div className={styles.flexPawn2}>
                  <div className={styles.headPawn}>
                    Einzelne ICE-Züge zwischen Frankfurt und Aschaffenburg
                  </div>
                  <div className={styles.subPawn}>Fällt heute aus.</div>
                  <div className={styles.subPawn}>
                    Grund dafür ist eines kurzfristigen Personalausfalls.
                  </div>
                </div>
                <div className={styles.flexPawn3}>
                  {signactive && <VideoPlayer index={'Satz11'} />}
                </div>
              </div>
            </div>

            <FooterV1 />
          </div>
        </RightClick>
      </div>
    );
  }
}
DisturbancesV1 = connect(
  (state) => ({
    active: state.overlay.active,
    device: state.main.device,
    vm: state.main.vm,
    debugMode: state.main.debugMode,
    signactive: state.main.signactive,
    scenario: state.main.scenario,
  }),
  {} // bind account loading action creator
)(DisturbancesV1);
export default withRouter(DisturbancesV1);
