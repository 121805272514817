import React, { Component, useEffect, useRef, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import ReactPlayer from 'react-player';
import { connect, useSelector } from 'react-redux';
import styles from './VideoPlayer2.module.scss';
import SubTitles from '../SubTitles/SubTitles';
import { data } from './VideoPlayer.data';
import './Slider.css';
import ReactSlider from 'react-slider';

const VideoPlayer = (props) => {
  
  const [videoData, setVideoData] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [visible, setVisible] = useState(false);
  const [subtitleText, setSubtitleText] = useState(videoData);
  const [played, setPlayed] = useState(0);
  const [trackLang, setTracklang] = useState();
  const [size, setSize] = useState(100);

  const player = useRef();

  const vid = useSelector((state) => state.overlay);
  const main = useSelector((state) => state.main);
  useEffect(() => {
    const textTracks = player.current.getInternalPlayer()?.textTracks;

    for (var i = 0; textTracks?.length && i < textTracks.length; i++) {
      // For the 'subtitles-off' button, the first condition will never match so all will subtitles be turned off
      if (textTracks[i].language === trackLang) {
        textTracks[i].mode = 'showing';
      } else {
        textTracks[i].mode = 'hidden';
      }
    }
    setTimeout(() => {
      init(props.index, vid.video);
    }, 200);
  }, [trackLang]);

  const init = (index, video) => {
    let onEnd = null;

    let videoData = data.find((entry) => entry.index === index);
    if (!videoData && video) {
      videoData = data.find((entry) => entry.index === video);
      if (!videoData && video.name) {
        videoData = data.find((entry) => {
          return entry.index === video.name;
        });
      }
    }
    const subtitleActive = main.variant === '2' || main.variant === '4';
    if (subtitleActive) {
      videoData && setSubtitleText(videoData.subtitle);

      console.log(videoData.subtitle);
    }

    if (video && video.onEnd) {
      onEnd = video.onEnd;
    }
    if (main.variant === '3' || main.variant === '4') {
      setPlaying(true);
    }
    setVideoData(videoData);
  };

  let url = videoData
    ? `${process.env.PUBLIC_URL}/assets/videos/${videoData.fileName}`
    : null;

  const width = size;

  return (
    <div
      className={styles.main}
      style={{
        width: `${width}%`,
      
      }}
    >
      {
        <ReactPlayer
          ref={player}
          className="react-player"
          url={url}
          width="100%"
          height="100%"
          loop={false}
          playing={playing && url ? true : false}
          progressInterval={1}
          onEnded={() => {
            if (props.onEnd) {
              props.onEnd();
            } 
            
            setSize(100);
          }}
          onMouseEnter={(e) => {
            setVisible(true);
          }}
          onMouseLeave={(e) => {
            setVisible(false);
          }}
          onProgress={(changeState) => {
            setPlayed(changeState.played * 100000);
          }}
          config={{
            file: {
              attributes: {
                crossOrigin: 'true',
              },
              tracks: [
                {
                  kind: 'subtitles',
                  src: `${process.env.PUBLIC_URL}/assets/subtitles/${subtitleText}`,
                  srcLang: 'en',
                  default: true,
                  mode: trackLang === 'en' ? 'showing' : 'hidden',
                },
              ],
            },
          }}
        />
      }

      {props.onClose && (
        <div className={styles.close}>
          <Button
            size={'mini'}
            icon
            onClick={() => {
              props.onClose();
            }}
          >
            <Icon color="red" name="close" />
          </Button>
        </div>
      )}

      <div
        className={styles.controlbar}
        onMouseEnter={(e) => {
          setVisible(true);
        }}
        onMouseLeave={(e) => {
          setVisible(false);
        }}
      >
        <div className={visible ? styles.bar : styles.barInvisble}>
          <div className={styles.barLeft}>
            <div className={styles.buttons}>
              {!playing && (
                <Button size="mini" icon onClick={() => setPlaying(true)}>
                  <Icon name="play" />
                </Button>
              )}

              {playing && (
                <Button size="mini" icon onClick={() => setPlaying(false)}>
                  <Icon name="stop" />
                </Button>
              )}
              <Button
                size="mini"
                icon
                onClick={() => {
                  player.current.seekTo(0);
                }}
              >
                <Icon name="undo" />
              </Button>
            </div>
            <div className={styles.seekBar}>
              <ReactSlider
                className="customSlider"
                trackClassName="customSlider-track"
                thumbClassName="customSlider-thumb"
                min={0}
                max={100000}
                value={played}
                onChange={(value) => {
                  setPlayed(value);
                  player.current.seekTo(value / 100000, 'fraction');
                  console.log(value);
                }}
              />
            </div>
          </div>
          {main.scenario != '3' && (
            <div className={styles.barRight}>
              {size === 100 && (
                <Button
                  icon
                  size="mini"
                  onClick={() => {
                    setSize(150);
                  }}
                >
                  <Icon name="expand" />
                </Button>
              )}
              {size === 150 && (
                <Button
                  icon
                  size="mini"
                  onClick={() => {
                    setSize(100);
                  }}
                >
                  <Icon name="compress" />
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
