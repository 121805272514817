export const parseQueryString = (query) => {
  return (query || '').split('&').reduce((acc, pair) => {
    const match = pair.match(/^\??(.+?)(?:=(.*))?$/);
    if (match) {
      let value = 'true';
      if (match[2]) {
        value = match[2].split('+').map(decodeURIComponent);
        if (value.length === 1) {
          value = value[0];
        }
      }

      acc[decodeURIComponent(match[1])] = value;
    }
    return acc;
  }, {});
};

export const stringifyQuery = (query) => {
  if (!query) return '';
  const keys = Object.keys(query);
  if (!keys || keys.length === 0) return '';
  let result =
    '?' +
    keys
      .map((key) => {
        if (!query[key]) return undefined;
        const value = query[key];
        const encodedKey = encodeURIComponent(key);
        let encodedValue;
        if (Array.isArray(value)) {
          encodedValue = value.map((v) => encodeURIComponent(v + '')).join('+');
        } else if (value !== true) {
          encodedValue = encodeURIComponent(value + '');
        }
        return encodedValue ? `${encodedKey}=${encodedValue}` : encodedKey;
      })
      .filter(Boolean)
      .join('&');

  return result.length > 1 ? result : '';
};
