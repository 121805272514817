import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Breadcrumb, Divider, Icon } from 'semantic-ui-react';
import FooterV3 from '../../../2_molecules/FooterV3/FooterV3';
import HeadV3 from '../../../2_molecules/HeadV3/HeadV3';
import styles from './Disturbances.module.scss';
import {
  actionSetOverlayActive,
  actionSetOverlayVideo,
} from '../../../../redux/actions/actionOverlay';
import store from '../../../../redux/store';
import { connect } from 'react-redux';

import RightClick from '../../../2_molecules/RightClick/RightClick';
import FooterFather from '../../../2_molecules/FooterFather/FooterFather';
import { redirectToSoSci } from '../../../../helpers/sosci.helper';

class DisturbancesV3 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { active, signactive, scenario, onEnd } = this.props;
    return (
      <div>
        <RightClick>
          <div className={styles.page}>
            <HeadV3 />
            <div
              className={
                scenario === '3' ? styles.breadContain_mo : styles.breadContain
              }
            >
              <Breadcrumb>
                <Breadcrumb.Section
                  link
                  onClick={() => this.props.history.push('/landingv3/5')}
                >
                  Startseite
                </Breadcrumb.Section>
                <Icon name="angle right" />
                <Breadcrumb.Section active>Störungen</Breadcrumb.Section>
              </Breadcrumb>
            </div>
            <div
              className={scenario === '3' ? styles.master_mo : styles.master}
            >
              <div className={styles.flexKing}>
                <div
                  className={
                    scenario === '3' ? styles.flexPawn1_mo : styles.flexPawn1
                  }
                >
                  <Icon size="big" name="info sign" />
                </div>
                <div className={styles.flexPawn2}>
                  <div
                    className={signactive && styles.icon}
                    onClick={() => {
                      signactive &&
                        store.dispatch(actionSetOverlayActive(true));
                      store.dispatch(
                        actionSetOverlayVideo({
                          name: 'Satz4',
                          onEnd: () => {
                            if (scenario === '3') {
                              redirectToSoSci();
                            }
                          },
                        })
                      );
                    }}
                  >
                    <div className={styles.headPawn}>ICE 1557 von Mainz</div>
                    <div className={styles.subPawn}>
                      Ankunft: 8:41 Uhr - fällt heute aus.
                    </div>
                    <div className={styles.subPawn}>
                      Grund dafür ist eine technische Störung am Zug.
                    </div>
                  </div>
                </div>
              </div>
              <Divider />
              <div className={styles.flexKing}>
                <div
                  className={
                    scenario === '3' ? styles.flexPawn1_mo : styles.flexPawn1
                  }
                >
                  <Icon size="big" name="info sign" />
                </div>
                <div className={styles.flexPawn2}>
                  <div
                    className={signactive && styles.icon}
                    onClick={() => {
                      signactive &&
                        store.dispatch(actionSetOverlayActive(true));
                      store.dispatch(
                        actionSetOverlayVideo({
                          name: 'Satz11',
                        })
                      );
                    }}
                  >
                    <div className={styles.headPawn}> ICE 651 von Köln</div>
                    <div className={styles.subPawn}>
                      Ankunft:17:20 - fällt heute aus.
                    </div>
                    <div className={styles.subPawn}>
                      Grund dafür ist eine technische Störung am Zug.
                    </div>
                  </div>
                </div>
              </div>
              <Divider />
              <div className={styles.flexKing}>
                <div
                  className={
                    scenario === '3' ? styles.flexPawn1_mo : styles.flexPawn1
                  }
                >
                  <Icon size="big" name="info sign" />
                </div>
                <div className={styles.flexPawn2}>
                  <div
                    className={signactive && styles.icon}
                    onClick={() => {
                      signactive &&
                        store.dispatch(actionSetOverlayActive(true));
                      store.dispatch(actionSetOverlayVideo('Satz11'));
                    }}
                  >
                    <div className={styles.headPawn}>
                      Einzelne ICE-Züge zwischen Frankfurt und Aschaffenburg
                    </div>
                    <div className={styles.subPawn}>Fällt heute aus.</div>
                    <div className={styles.subPawn}>
                      Grund dafür ist eines kurzfristigen Personalausfalls.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <FooterFather scenario={scenario} />
          </div>
        </RightClick>
      </div>
    );
  }
}
DisturbancesV3 = connect(
  (state) => ({
    active: state.overlay.active,
    device: state.main.device,
    vm: state.main.vm,
    debugMode: state.main.debugMode,
    signactive: state.main.signactive,
    scenario: state.main.scenario,
  }),
  {} // bind account loading action creator
)(DisturbancesV3);
export default withRouter(DisturbancesV3);
