import React, { Component } from 'react';
import {
  Grid,
  Icon,
  Button,
  Header,
  Divider,
  Modal,
  Breadcrumb,
} from 'semantic-ui-react';
import { redirectToSoSci } from '../../../../../helpers/sosci.helper';
import styles from './TicketsV3.module.scss';
import store from '../../../../../redux/store';
import { connect } from 'react-redux';
import {
  actionSetOverlayActive,
  actionSetOverlayVideo,
} from '../../../../../redux/actions/actionOverlay';
import { withRouter } from 'react-router-dom';
import HeadV3 from '../../../../2_molecules/HeadV3/HeadV3';
import FooterV3 from '../../../../2_molecules/FooterV3/FooterV3';
import FooterFather from '../../../../2_molecules/FooterFather/FooterFather';
class TicketsV3 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { signactive, active, scenario } = this.props;
    const {} = this.state;
    return (
      <div className={styles.page}>
        <HeadV3 />
        <div
          className={
            scenario === '3' ? styles.breadContain_mo : styles.breadContain
          }
        >
          <Breadcrumb>
            <Breadcrumb.Section
              onClick={() => this.props.history.push('/landingv3')}
              link
            >
              Startseite
            </Breadcrumb.Section>
            <Icon name="angle right" />
            <Breadcrumb.Section active>Verbindungssuche</Breadcrumb.Section>
          </Breadcrumb>{' '}
        </div>

        <div className={styles.pickContainer1}>
          <div
            className={
              scenario === '3'
                ? styles.pickContainer2_mo
                : styles.pickContainer2
            }
          >
            <Header>Hinfahrt am Montag 30. Oktober 2022</Header>
            <lbl>
              <Icon name="bullseye" /> Köln
            </lbl>
            <lbl>
              <Icon name="point" />
              Essen
            </lbl>
            <br />
            <br />
            <lbl>
              <Icon name="user" />1 reisende Person 1.Klasse
            </lbl>
            <br />
            <br />
            <Button
              color="blue"
              basic
              onClick={() => this.props.history.push('/verbindungsv3')}
            >
              Zurück zur Suche
            </Button>
          </div>

          <div>
            <div
              className={
                scenario === '3'
                  ? styles.pickContainer2_mo
                  : styles.pickContainer2
              }
            >
              <Grid stackable celled="internally">
                <Grid.Column width={13}>
                  <Header>7:30 - 10:05/ 2:35h</Header>

                  <br />
                  <div
                    className={signactive && styles.icon}
                    onClick={() => {
                      signactive &&
                        store.dispatch(actionSetOverlayActive(true));
                      store.dispatch(actionSetOverlayVideo('SatzCol59103'));
                    }}
                  >
                    <lbl>
                      Für diese Fahrt kannst du das günstige 9 € Ticket nutzen.
                      <br />
                      Deine Reise dauert voraussichtlich 2 Stunden und 35
                      Minuten.
                      <br />
                      Wir erwarten im Verlauf Ihrer Reise eine hohe Auslastung.
                      Reservieren Sie bereits jetzt Ihren Wunschplatz.
                      <br />
                      Auf Deiner Reise nach Essen müssen Sie zweimal umsteigen.{' '}
                      <br />
                      <Icon name="point" /> Köln
                      <Icon name="map pin" /> Düsseldorf
                      <Icon name="map pin" /> Duisburg
                      <Icon name="point" /> Essen
                    </lbl>
                  </div>
                  <br />
                </Grid.Column>
                <Grid.Column textAlign="center" width={3}>
                  <lbl className={styles.price}>ab 9 € </lbl>
                  <br />
                  <br />
                  <Grid.Row>
                    <Divider />
                    <Button
                      onClick={() => {
                        redirectToSoSci();
                      }}
                      size="huge"
                      color="blue"
                    >
                      Buchen
                    </Button>
                  </Grid.Row>
                </Grid.Column>
              </Grid>
            </div>

            <div
              className={
                scenario === '3'
                  ? styles.pickContainer2_mo
                  : styles.pickContainer2
              }
            >
              <Grid stackable celled="internally">
                <Grid.Column width={13}>
                  <Header>9:35 - 12:10 / 2:35h</Header>
                  <br />
                  <div
                    className={signactive && styles.icon}
                    onClick={() => {
                      signactive &&
                        store.dispatch(actionSetOverlayActive(true));
                      store.dispatch(actionSetOverlayVideo('SatzCol5910b3'));
                    }}
                  >
                    <lbl>
                      Für diese Fahrt kannst du das günstige 9 € Ticket nutzen.
                      <br />
                      Deine Reise dauert voraussichtlich 2 Stunden und 35
                      Minuten.
                      <br /> Wir erwarten im Verlauf Ihrer Reise eine niedrige
                      Auslastung. Reservieren Sie bereits jetzt Ihren
                      Wunschplatz.
                      <br />
                      Auf Deiner Reise nach Essen müssen Sie zweimal umsteigen.
                      <br />
                      <Icon name="point" /> Köln
                      <Icon name="map pin" /> Düsseldorf
                      <Icon name="map pin" /> Duisburg
                      <Icon name="point" /> Essen
                    </lbl>
                  </div>
                  <br />
                </Grid.Column>
                <Grid.Column textAlign="center" width={3}>
                  <lbl className={styles.price}>ab 9 € </lbl>
                  <br />
                  <br />
                  <Grid.Row>
                    <Divider />
                    <Button
                      onClick={() => {
                        redirectToSoSci();
                      }}
                      size="huge"
                      color="blue"
                    >
                      Buchen
                    </Button>
                  </Grid.Row>
                </Grid.Column>
              </Grid>
            </div>
            <div
              className={
                scenario === '3'
                  ? styles.pickContainer2_mo
                  : styles.pickContainer2
              }
            >
              <Grid stackable celled="internally">
                <Grid.Column width={13}>
                  <Header>13:05 - 14:40 / 2:35h</Header>

                  <br />
                  <div
                    className={signactive && styles.icon}
                    onClick={() => {
                      signactive &&
                        store.dispatch(actionSetOverlayActive(true));
                      store.dispatch(actionSetOverlayVideo('Satz10'));
                      /*Nur Satz10 Hier weil das ist die inkorrekt ticket */
                    }}
                  >
                    <lbl>
                      Deine Reise dauert voraussichtlich 1 Stunden und 35
                      Minuten.
                      <br />
                      Wir erwarten im Verlauf Ihrer Reise eine hohe Auslastung.
                      Reservieren Sie bereits jetzt Ihren Wunschplatz.
                    </lbl>
                  </div>
                </Grid.Column>
                <Grid.Column textAlign="center" width={3}>
                  <lbl className={styles.price}>ab 35 € </lbl>
                  <Grid.Row>
                    <Divider />
                    <Button
                      onClick={() => {
                        redirectToSoSci();
                      }}
                      size="huge"
                      color="blue"
                    >
                      Buchen
                    </Button>
                  </Grid.Row>
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </div>
        <FooterFather scenario={scenario} />
      </div>
    );
  }
}
TicketsV3 = connect(
  (state) => ({
    search: state.ticket.search,
    signactive: state.main.signactive,
    scenario: state.main.scenario,
    active: state.overlay.active,
  }),
  {} // bind account loading action creator
)(TicketsV3);

export default withRouter(TicketsV3);
