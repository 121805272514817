import React, { Component } from 'react';
import {
  Grid,
  Icon,
  Button,
  Header,
  Segment,
  Divider,
  Modal,
  Breadcrumb,
} from 'semantic-ui-react';
import styles from './TicketsV1.module.scss';
import { redirectToSoSci } from '../../../../../helpers/sosci.helper';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import VideoPlayer2 from '../../../../2_molecules/VideoPlayer2/VideoPlayer2';
import HeadV1 from '../../../../2_molecules/HeadV1/HeadV1';
import FooterV1 from '../../../../2_molecules/FooterV1/FooterV1';
import VideoPlayer from '../../../../2_molecules/VideoPlayer2/VideoPlayer';

class TicketsV1 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { search, signactive, scenario } = this.props;
    const {} = this.state;
    return (
      <div className={styles.page}>
        <HeadV1 />
        <div className={styles.breadContain}>
          <Breadcrumb>
            <Breadcrumb.Section
              onClick={() => this.props.history.push('/landingv1')}
              link
            >
              Startseite
            </Breadcrumb.Section>
            <Icon name="angle right" />
            <Breadcrumb.Section active>Verbindungssuche</Breadcrumb.Section>
          </Breadcrumb>{' '}
        </div>
        <div className={styles.pickContainer1}>
          <div className={styles.pickContainer2}>
            <Header>Hinfahrt am Montag 30. Oktober 2022</Header>
            <lbl>
              <Icon name="bullseye" /> Köln
            </lbl>
            <lbl>
              <Icon name="point" />
              Essen
            </lbl>
            <br />
            <br />
            <lbl>
              <Icon name="user" />1 reisende Person 1.Klasse
            </lbl>
            <br />
            <br />
            <Button
              color="blue"
              basic
              onClick={() => this.props.history.push('/verbindungsv1')}
            >
              Zurück zur Suche
            </Button>
          </div>
          {!signactive && (
            <div>
              <div className={styles.pickContainer2}>
                <Grid stackable celled="internally">
                  <Grid.Column width={13}>
                    <Header>7:30 - 10:05/ 2:35h</Header>
                    <br />
                    <lbl>
                      Für diese Fahrt kannst du das günstige 9 € Ticket nutzen.
                      <br />
                      Deine Reise dauert voraussichtlich 2 Stunden und 35
                      Minuten.
                      <br />
                      Wir erwarten im Verlauf Ihrer Reise eine hohe Auslastung.
                      Reservieren Sie bereits jetzt Ihren Wunschplatz.
                      <br />
                      Auf Deiner Reise nach Essen müssen Sie zweimal umsteigen.{' '}
                      <br />
                      <Icon name="point" /> Köln
                      <Icon name="map pin" /> Düsseldorf
                      <Icon name="map pin" /> Duisburg
                      <Icon name="point" /> Essen
                    </lbl>

                    <br />
                  </Grid.Column>
                  <Grid.Column textAlign="center" width={3}>
                    <lbl className={styles.price}>ab 9 € </lbl>
                    <br />
                    <br />
                    <Grid.Row>
                      <Divider />
                      <Button
                        onClick={() => {
                          redirectToSoSci();
                        }}
                        size="huge"
                        color="blue"
                      >
                        Buchen
                      </Button>
                    </Grid.Row>
                  </Grid.Column>
                </Grid>
              </div>
              <div className={styles.pickContainer2}>
                <Grid stackable celled="internally">
                  <Grid.Column width={13}>
                    <Header>9:35 - 12:10 / 2:35h</Header>
                    <br />
                    <lbl>
                      Für diese Fahrt kannst du das günstige 9 € Ticket nutzen.
                      <br />
                      Deine Reise dauert voraussichtlich 2 Stunden und 35
                      Minuten.
                      <br />
                      Wir erwarten im Verlauf Ihrer Reise eine niedrige
                      Auslastung. Reservieren Sie bereits jetzt Ihren
                      Wunschplatz.
                      <br />
                      Auf Deiner Reise nach Essen müssen Sie zweimal umsteigen.{' '}
                      <br />
                      <Icon name="point" /> Köln
                      <Icon name="map pin" /> Düsseldorf
                      <Icon name="map pin" /> Duisburg
                      <Icon name="point" /> Essen
                    </lbl>

                    <br />
                  </Grid.Column>
                  <Grid.Column textAlign="center" width={3}>
                    <lbl className={styles.price}>ab 9 € </lbl>
                    <br />
                    <br />
                    <Grid.Row>
                      <Divider />
                      <Button
                        onClick={() => {
                          redirectToSoSci();
                        }}
                        size="huge"
                        color="blue"
                      >
                        Buchen
                      </Button>
                    </Grid.Row>
                  </Grid.Column>
                </Grid>
              </div>

              <div className={styles.pickContainer2}>
                <Grid stackable celled="internally">
                  <Grid.Column width={13}>
                    <Header>13:05 - 14:40 / 2:35h</Header>

                    <br />
                    <lbl>
                      Deine Reise dauert voraussichtlich 1 Stunden und 35
                      Minuten.
                      <br />
                      Wir erwarten im Verlauf Ihrer Reise eine hohe Auslastung.
                      Reservieren Sie bereits jetzt Ihren Wunschplatz.
                    </lbl>
                  </Grid.Column>
                  <Grid.Column textAlign="center" width={3}>
                    <lbl className={styles.price}>ab 35 € </lbl>
                    <br />
                    <br />
                    <Grid.Row>
                      <Divider />
                      <Button
                        onClick={() => {
                          redirectToSoSci();
                        }}
                        size="huge"
                        color="blue"
                      >
                        Buchen
                      </Button>
                    </Grid.Row>
                  </Grid.Column>
                </Grid>
              </div>
            </div>
          )}
          {signactive && (
            <div>
              <div className={styles.pickContainer2}>
                <Grid stackable celled="internally">
                  <Grid.Column width={7}>
                    <Header>7:30 - 10:05/ 2:35h</Header>
                    <br />
                    <lbl>
                      Für diese Fahrt kannst du das günstige 9 € Ticket nutzen.
                      <br />
                      Deine Reise dauert voraussichtlich 2 Stunden und 35
                      Minuten.
                      <br />
                      Wir erwarten im Verlauf Ihrer Reise eine hohe Auslastung.
                      Reservieren Sie bereits jetzt Ihren Wunschplatz.
                      <br />
                      Auf Deiner Reise nach Essen müssen Sie zweimal umsteigen.{' '}
                      <br />
                      <Icon name="point" /> Köln
                      <Icon name="map pin" /> Düsseldorf
                      <Icon name="map pin" /> Duisburg
                      <Icon name="point" /> Essen
                    </lbl>

                    <br />
                  </Grid.Column>
                  <Grid.Column textAlign="center" width={3}>
                    <lbl className={styles.price}>ab 9 € </lbl>
                    <br />
                    <br />
                    <Grid.Row>
                      <Divider />
                      <Button
                        onClick={() => {
                          redirectToSoSci();
                        }}
                        size="huge"
                        color="blue"
                      >
                        Buchen
                      </Button>
                    </Grid.Row>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <VideoPlayer index={'SatzCol59103'} />
                  </Grid.Column>
                </Grid>
              </div>
              <div className={styles.pickContainer2}>
                <Grid stackable celled="internally">
                  <Grid.Column width={7}>
                    <Header>9:35 - 12:10 / 2:35h</Header>
                    <br />
                    <lbl>
                      Für diese Fahrt kannst du das günstige 9 € Ticket nutzen.
                      <br />
                      Deine Reise dauert voraussichtlich 2 Stunden und 35
                      Minuten.
                      <br />
                      Wir erwarten im Verlauf Ihrer Reise eine niedrige
                      Auslastung. Reservieren Sie bereits jetzt Ihren
                      Wunschplatz.
                      <br />
                      Auf Deiner Reise nach Essen müssen Sie zweimal umsteigen.{' '}
                      <br />
                      <Icon name="point" /> Köln
                      <Icon name="map pin" /> Düsseldorf
                      <Icon name="map pin" /> Duisburg
                      <Icon name="point" /> Essen
                    </lbl>

                    <br />
                  </Grid.Column>
                  <Grid.Column textAlign="center" width={3}>
                    <lbl className={styles.price}>ab 9 € </lbl>
                    <br />
                    <br />
                    <Grid.Row>
                      <Divider />
                      <Button
                        onClick={() => {
                          redirectToSoSci();
                        }}
                        size="huge"
                        color="blue"
                      >
                        Buchen
                      </Button>
                    </Grid.Row>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <VideoPlayer index={'SatzCol5910b3'} />
                  </Grid.Column>
                </Grid>
              </div>

              <div className={styles.pickContainer2}>
                <Grid stackable celled="internally">
                  <Grid.Column width={7}>
                    <Header>13:05 - 14:40/ 1:35h</Header>

                    <br />
                    <lbl>
                      Deine Reise dauert voraussichtlich 1 Stunden und 35
                      Minuten.
                      <br />
                      Wir erwarten im Verlauf Ihrer Reise eine hohe Auslastung.
                      Reservieren Sie bereits jetzt Ihren Wunschplatz.
                    </lbl>
                  </Grid.Column>
                  <Grid.Column textAlign="center" width={3}>
                    <lbl className={styles.price}>ab 35 € </lbl>
                    <br />
                    <br />
                    <Grid.Row>
                      <Divider />
                      <Button
                        onClick={() => {
                          redirectToSoSci();
                        }}
                        size="huge"
                        color="blue"
                      >
                        Buchen
                      </Button>
                    </Grid.Row>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <VideoPlayer index={'Satz11'} />
                  </Grid.Column>
                </Grid>
              </div>
            </div>
          )}
        </div>
        <FooterV1 />
      </div>
    );
  }
}
TicketsV1 = connect(
  (state) => ({
    search: state.ticket.search,
    signactive: state.main.signactive,
    scenario: state.main.scenario,
  }),
  {} // bind account loading action creator
)(TicketsV1);

export default withRouter(TicketsV1);
