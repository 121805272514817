import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import styles from './App.module.scss';

import {
  actionSetApiBase,
  actionSetDevice,
  actionSetDebugMode,
  actionSetScenario,
  actionSetVariant,
  actionSetToken,
} from '../../../redux/actions/actionMain';
import store from '../../../redux/store';
import RestAPI from '../../../RestAPI.js';

import VariantPick from '../../2_molecules/VariantPick/VariantPick';

import TicketFaqV3 from '../Variant3/TicketFaqV3/TicketFaqV3';
import DisturbancesV3 from '../Variant3/DisturbancesV3/DisturbancesV3';
import LandingV3 from '../Variant3/LandingV3/LandingV3';
import BookingV3 from '../Variant3/BookingV3/BookingV3/BookingV3';
import TickerV3 from '../Variant3/TickerV3/TickerV3';
import BahnUmweltV3 from '../Variant3/BahnUmweltV3/BahnUmweltV3';
import NoTicketsV3 from '../Variant3/BookingV3/NoTickets/NoTicketsV3';
import TicketsV3 from '../Variant3/BookingV3/Tickets/TicketsV3';

import HandyV1 from '../Variant1/HandyPushNot/HandyV1';
import NoTicketsV1 from '../Variant1/BookingV1/NoTickets/NoTicketsV1';
import TicketFaqV1 from '../Variant1/TicketFaqV1/TicketFaqV1';
import DisturbancesV1 from '../Variant1/DisturbancesV1/DisturbancesV1';
import LandingV1 from '../Variant1/LandingV1/LandingV1';
import BookingV1 from '../Variant1/BookingV1/BookingV1/BookingV1';
import TickerV1 from '../Variant1/TickerV1/TickerV1';
import BahnUmweltV1 from '../Variant1/BahnUmweltV1/BahnUmweltV1';
import TicketsV1 from '../Variant1/BookingV1/Tickets/TicketsV1';

import { reactLocalStorage } from 'reactjs-localstorage';

import ReactGA from 'react-ga';
import Landing from '../Landing/Landing';
import NotFound404V1 from '../Variant1/NotFound404V1/NotFound404V1';
import { setStartTime } from '../../../helpers/sosci.helper';
import { cx } from '../../../helpers/classNames.helper';
import { DemoPick } from '../DemoPick/DemoPick';
const MobileDetect = require('mobile-detect');

const TRACKING_ID = 'UA-242095029-1'; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const md = new MobileDetect(window.navigator.userAgent);

/**** App ***************************************************************/
/*!
 **
 ******************************************************************************/
class App extends Component {
  constructor(props) {
    super(props);

    let title = 'demonstrator2';

    if (process.env.NODE_ENV !== 'production') {
      title += ' ' + process.env.NODE_ENV;
    }

    this.state = {
      loggingMessage: '',
      mode: 'init',
      title: title,
    };

    setStartTime();

    store.dispatch(actionSetDevice(md.mobile()));
  }

  render() {
    const { login, scenario, variant, token, param_number } = this.props;
    const { mode } = this.state;
    const mobile = scenario === '3' && (variant === '3' || variant === '4');
    return (
      <div>
        <div className={mobile ? styles.mobile : styles.desktop}>
          <div className={styles.main}>
            <Helmet>
              <title>{this.state.title}</title>
            </Helmet>
            {mode === 'loading....' ? (
              <div>init</div>
            ) : (
              <Switch>
                <Route
                  path="/"
                  exact
                  render={(props) => {
                    return <DemoPick />;
                  }}
                />
                 <Route
                  path="/hereweCanchooseOurVariantandScenario"
                  exact
                  render={(props) => {
                    return <VariantPick />;
                  }}
                />
                <Route
                  path="/scenario/:scenario/variant/:variant"
                  exact
                  render={(props) => {
                    return <Landing />;
                  }}
                />

                <Route
                  path="/landingv3/:variant"
                  exact
                  render={(props) => {
                    return <LandingV3 />;
                  }}
                />
                <Route
                  path="/aktuellesv3"
                  exact
                  render={(props) => {
                    return <TickerV3 />;
                  }}
                />
                <Route
                  path="/umweltv3"
                  exact
                  render={(props) => {
                    return <BahnUmweltV3 />;
                  }}
                />
                <Route
                  path="/9faqv3"
                  exact
                  render={(props) => {
                    return <TicketFaqV3 />;
                  }}
                />
                <Route
                  path="/storungenv3"
                  exact
                  render={(props) => {
                    return <DisturbancesV3 />;
                  }}
                /> 
                <Route
                  path="/ticketsv3"
                  exact
                  render={(props) => {
                    return <TicketsV3 />;
                  }}
                />
                   <Route
                  path="/verbindungsv3"
                  exact
                  render={(props) => {
                    return <BookingV3 />;
                  }}
                />
                <Route
                  path="/ticketsv1"
                  exact
                  render={(props) => {
                    return <TicketsV1 />;
                  }}
                />

                <Route
                  path="/landingv1/:variant"
                  exact
                  render={(props) => {
                    return <LandingV1 />;
                  }}
                />
                <Route
                  path="/verbindungsv1"
                  exact
                  render={(props) => {
                    return <BookingV1 />;
                  }}
                />
                <Route
                  path="/keinticketv1"
                  exact
                  render={(props) => {
                    return <NoTicketsV1 />;
                  }}
                />
                <Route
                  path="/aktuellesv1"
                  exact
                  render={(props) => {
                    return <TickerV1 />;
                  }}
                />
                <Route
                  path="/umweltv1"
                  exact
                  render={(props) => {
                    return <BahnUmweltV1 />;
                  }}
                />
                <Route
                  path="/9faqv1"
                  exact
                  render={(props) => {
                    return <TicketFaqV1 />;
                  }}
                />
                <Route
                  path="/storungenv1"
                  exact
                  render={(props) => {
                    return <DisturbancesV1 />;
                  }}
                />
                <Route
                  path="/scenario3"
                  render={(props) => {
                    return <HandyV1 />;
                  }}
                />
              </Switch>
            )}
          </div>
        </div>
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
App = connect(
  (state) => ({
    login: state.login.login,
    token: state.main.token,
    param_number: state.main.param_number,
    scenario: state.main.scenario,
    variant: state.main.variant,
    device: state.main.device,
    vm: state.main.vm,
    debugMode: state.main.debugMode,
    end: state.main.end,
  }),
  {} // bind account loading action creator
)(App);

export default withRouter(App);
