import _ from 'lodash';
import React, { Component } from 'react';
import { Breadcrumb, Divider, Grid, Header, Segment } from 'semantic-ui-react';
import HeadV3 from '../../../2_molecules/HeadV3/HeadV3';
import { withRouter } from 'react-router-dom';
import FooterV3 from '../../../2_molecules/FooterV3/FooterV3';
import styles from './Landing.module.scss';
import FooterFather from '../../../2_molecules/FooterFather/FooterFather';
import { connect } from 'react-redux';

import VideoPlayer2 from '../../../2_molecules/VideoPlayer2/VideoPlayer2';
import RightClick from '../../../2_molecules/RightClick/RightClick';
import store from '../../../../redux/store';
import { actionSetOverlayActive, actionSetOverlayVideo } from '../../../../redux/actions/actionOverlay';

class LandingV3 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
  }

  render() {
    const {} = this.state;
    const { active, signactive, bigScreen , scenario } = this.props;

    return (
      <div className={styles.page}>
        <HeadV3 />

        <div className={scenario=== "3" ? styles.breadContain_mo : styles.breadContain}>
          <Breadcrumb>
            <Breadcrumb.Section active>Startseite</Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <RightClick>
          <div>
           
              <div>
              <div className={scenario==="3" ? styles.landBum_mo : styles.landBum}>
              <div className={scenario==="3" ? styles.landBum1_mo : styles.landBum1}>
                    <img
                      className={styles.pict2}
                      src={`${process.env.PUBLIC_URL}/assets/images/masken_pflicht.jpg`}
                    />
                  </div>
                  <div className={scenario==="3" ? styles.landBum2_mo : styles.landBum2}>
                    <div className={styles.bumHead}>
                      Weiterhin Maskenpflicht in Bus & Bahn
                    </div>
                    <div className={styles.bumText}>
                    <div
                        className={signactive && styles.icon}
                        onClick={() => { signactive && 
                          store.dispatch(actionSetOverlayActive(true));
                          store.dispatch(actionSetOverlayVideo('Satz11'));
                        }}
                      >
                      Bitte tragen Sie eine OP- oder FFP2-Maske.Im Nahverkehr in
                      Deutschland gilt weiterhin die Maskenpflicht. Unabhängig
                      vom Inzidenzwert können folgende Masken getragen werden:
                      OP-Masken Atemschutzmasken mit dem Standard FFP2, KN95 und
                      N95.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={scenario==="3" ? styles.landBum_mo : styles.landBum}>
              <div className={scenario==="3" ? styles.landBum1_mo : styles.landBum1}>
                    <img
                      className={styles.pict2}
                      src={`${process.env.PUBLIC_URL}/assets/images/9_ticket.jpg`}
                    />
                  </div>
                  <div className={scenario==="3" ? styles.landBum2_mo : styles.landBum2}>
                    <div
                      onClick={() => this.props.history.push('/9faqv1')}
                      className={styles.bumHead}
                    >
                      Die Deutschland-Flatrate für Bus & Bahn
                    </div>
                    <div className={styles.bumText}>
                    <div
                        className={signactive && styles.icon}
                        onClick={() => { signactive && 
                          store.dispatch(actionSetOverlayActive(true));
                          store.dispatch(actionSetOverlayVideo('Satz11'));
                        }}
                      >
                      Verkaufsstart in der BD-App und in den Kundencentern am
                      23.Mai.Das Ticketabo für die 2. Klasse in Deutschland
                      kostet im Juni, Juli und August 2022 dann auch nur neun
                      Euro pro Monat.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            
     
              <FooterFather scenario={scenario} />
          </div>
        </RightClick>
      </div>
    );
  }
}
LandingV3 = connect(
  (state) => ({
    active: state.overlay.active,
    device: state.main.device,
    vm: state.main.vm,
    debugMode: state.main.debugMode,
    signactive: state.main.signactive,
    bigScreen: state.main.bigScreen,
    scenario: state.main.scenario
  }),
  {} // bind account loading action creator
)(LandingV3);

export default withRouter(LandingV3);
