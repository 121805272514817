import _ from 'lodash';
import React, { Component } from 'react';
import {
  Grid,
  Header,
  Segment,
  Breadcrumb,
  Icon,
  Button,
  Dropdown,
  Label,
} from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';

import { connect } from 'react-redux';
import styles from './Booking.module.scss';
import HeadV1 from '../../../../2_molecules/HeadV1/HeadV1';
import { withRouter } from 'react-router-dom';
import FooterV1 from '../../../../2_molecules/FooterV1/FooterV1';
import RightClick from '../../../../2_molecules/RightClick/RightClick';

/*
  <Image floated="left" src="https://via.placeholder.com/250" />
*/

class BookingV1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: null,
      to: null,
      date: null,
      hour: null,
      min: null,
    };
  }

  render() {
    const hourOpt = [
      {
        key: '01',
        value: '01',
        text: '01',
      },
      {
        key: '02',
        value: '02',
        text: '02',
      },
      {
        key: '03',
        value: '03',
        text: '03',
      },
      {
        key: '04',
        value: '04',
        text: '04',
      },
      {
        key: '05',
        value: '05',
        text: '05',
      },
      {
        key: '06',
        value: '06',
        text: '06',
      },
      {
        key: '07',
        value: '07',
        text: '07',
      },
      {
        key: '08',
        value: '08',
        text: '08',
      },
      {
        key: '09',
        value: '09',
        text: '09',
      },
      {
        key: '10',
        value: '10',
        text: '10',
      },
      {
        key: '11',
        value: '11',
        text: '11',
      },
      {
        key: '12',
        value: '12',
        text: '12',
      },
      {
        key: '13',
        value: '13',
        text: '13',
      },
      {
        key: '14',
        value: '14',
        text: '14',
      },
      {
        key: '15',
        value: '15',
        text: '15',
      },
      {
        key: '16',
        value: '16',
        text: '16',
      },
      {
        key: '17',
        value: '17',
        text: '17',
      },
      {
        key: '18',
        value: '18',
        text: '18',
      },
      {
        key: '19',
        value: '19',
        text: '19',
      },
      {
        key: '20',
        value: '20',
        text: '20',
      },
      {
        key: '21',
        value: '21',
        text: '21',
      },
      {
        key: '22',
        value: '22',
        text: '22',
      },
      {
        key: '23',
        value: '23',
        text: '23',
      },
      {
        key: '24',
        value: '24',
        text: '24',
      },
    ];
    const minOpt = [
      {
        key: '00',
        value: '00',
        text: '00',
      },
      {
        key: '10',
        value: '10',
        text: '10',
      },
      {
        key: '20',
        value: '20',
        text: '20',
      },
      {
        key: '30',
        value: '30',
        text: '30',
      },
      {
        key: '40',
        value: '40',
        text: '40',
      },
      {
        key: '50',
        value: '50',
        text: '50',
      },
    ];
    const cityOpt = [
      {
        key: 'Hamburg',
        value: 'Hamburg',
        text: 'Hamburg',
      },
      {
        key: 'Köln',
        value: 'Köln',
        text: 'Köln',
      },
      {
        key: 'Essen',
        value: 'Essen',
        text: 'Essen',
      },
      {
        key: 'Frankfurt',
        value: 'Frankfurt',
        text: 'Frankfurt',
      },
      {
        key: 'Dortmund',
        value: 'Dortmund',
        text: 'Dortmund',
      },
      {
        key: 'Berlin',
        value: 'Berlin',
        text: 'Berlin',
      },
    ];
    const { from, to, date } = this.state;

    console.log(window.location);

    return (
      <div className={styles.page}>
        <HeadV1 />{' '}
        <div className={styles.breadContain}>
          <Breadcrumb>
            <Breadcrumb.Section
              onClick={() => this.props.history.push('/landingv1')}
              link
            >
              Startseite
            </Breadcrumb.Section>
            <Icon name="angle right" />
            <Breadcrumb.Section active>Verbindungssuche</Breadcrumb.Section>
          </Breadcrumb>{' '}
        </div>
        <div className={styles.master}>
          <div className={styles.ticketFlex}>
            <div className={styles.ticketBing}>
              <Header size="large">Wohin soll die Reise gehen?</Header>
            </div>
            <div className={styles.ticketBing1}>VON:</div>
            <div className={styles.ticketBing2}>
              <Dropdown
                style={{ width: '205px' }}
                selection
                search
                options={cityOpt}
                placeholder="Stadt"
                onChange={(e, data) => {
                  this.setState({ from: data.value });
                  console.log('##', data.value);
                }}
              ></Dropdown>
            </div>

            <div className={styles.ticketBing3}>NACH:</div>
            <div className={styles.ticketBing4}>
              <Dropdown
                style={{ width: '205px' }}
                selection
                search
                options={cityOpt}
                placeholder="Stadt"
                onChange={(e, data) => {
                  this.setState({ to: data.value });
                  console.log('##', data.value);
                }}
              ></Dropdown>
            </div>
          </div>
          <div className={styles.ticketFlex}>
            <div className={styles.ticketBing5}>WANN:</div>
            <div className={styles.ticketBing6}>
              {' '}
              <SemanticDatepicker
                placeholder={'TT-MM-JJJJ'}
                locale="de-DE"
                format="DD-MM-YYYY"
                onChange={(e, data) => {
                  this.setState({ date: data.value });
                  console.log('##', data.value);
                }}
              />
            </div>

            <div className={styles.ticketBing7}>
              <Icon name="clock" /> :
            </div>
            <div className={styles.ticketBing8}>
              <Dropdown
                style={{ minWidth: '85px' }}
                selection
                search
                options={hourOpt}
                placeholder={'hh'}
                onChange={(e, data) => {
                  this.setState({ hour: data.value });
                  console.log('##', data.value);
                }}
              />
              <lbl className={styles.lvbelc1}>:</lbl>
              <Dropdown
                style={{ minWidth: '85px' }}
                selection
                search
                options={minOpt}
                placeholder={'mm'}
              />
            </div>
          </div>
          <div className={styles.ticketFlex}>
            <div className={styles.ticketBing9}>
              {from === 'Köln' &&
                to === 'Essen' &&
                date &&
                date.toISOString() === '2023-03-30T22:00:00.000Z' && (
                  <Button
                    color="blue"
                    onClick={() => {
                      this.props.history.push('ticketsv1');
                    }}
                  >
                    Suchen
                  </Button>
                )}
              {!(
                from === 'Köln' &&
                to === 'Essen' &&
                date &&
                date.toISOString() === '2023-03-30T22:00:00.000Z'
              ) && (
                <Button
                  color="blue"
                  onClick={() => {
                    this.props.history.push('/keinticketv1');
                  }}
                >
                  Suchen
                </Button>
              )}
            </div>
          </div>
        </div>{' '}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <FooterV1 />
      </div>
    );
  }
}
BookingV1 = connect(
  (state) => ({
    search: state.ticket.search,
    signactive: state.main.signactive,
  }),
  {} // bind account loading action creator
)(BookingV1);
export default withRouter(BookingV1);
