//
// Redux init
//
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
//import { reducer as formReducer } from 'redux-form'
import thunk from 'redux-thunk';
import Effects from './effects';

import effectOverlay from './effects/effectOverlay';

import reducerMain from './reducer/reducerMain';
import reducerUser from './reducer/reducerUser';
import reducerLogin from './reducer/reducerLogin';
import reducerOverlay from './reducer/reducerOverlay';
import reducerTicket from './reducer/reducerTicket';

const effects = new Effects([effectOverlay]);

const rootReducer = combineReducers({
  main: reducerMain,
  login: reducerLogin,
  user: reducerUser,
  overlay: reducerOverlay,
  ticket: reducerTicket,

});

const initialState = {};
const middleware = [];
middleware.push(effects, thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
