import _ from 'lodash';
import React, { Component } from 'react';
import {
  Breadcrumb,
  Divider,
  Grid,
  Header,
  Segment,
  Icon,
} from 'semantic-ui-react';
import FooterV1 from '../../../2_molecules/FooterV1/FooterV1';
import HeadV1 from '../../../2_molecules/HeadV1/HeadV1';

import styles from './Ticker.module.scss';

import VideoPlayer2 from '../../../2_molecules/VideoPlayer2/VideoPlayer2';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RightClick from '../../../2_molecules/RightClick/RightClick';
import VideoPlayer from '../../../2_molecules/VideoPlayer2/VideoPlayer';

class TickerV1 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {} = this.state;
    const { active, signactive } = this.props;
    return (
      <div className={styles.page}>
        <RightClick>
          <div>
            <HeadV1 />
            <div className={styles.breadContain}>
              <Breadcrumb>
                <Breadcrumb.Section
                  onClick={() => this.props.history.push('/landingv1')}
                  link
                >
                  Startseite
                </Breadcrumb.Section>
                <Icon name="angle right" />
                <Breadcrumb.Section active>Aktuelles</Breadcrumb.Section>
              </Breadcrumb>{' '}
            </div>
            {!signactive && (
              <div>
                <div className={styles.landBum}>
                  <div className={styles.landBum1}>
                    <img
                      className={styles.pict2}
                      src={`${process.env.PUBLIC_URL}/assets/images/treue_bonus.jpg`}
                    />
                  </div>
                  <div className={styles.landBum2}>
                    <div className={styles.bumHead}>September & Oktober</div>
                    <div className={styles.bumText}>
                      Abonnenten-Treuebonus : An den Wochenenden und in den
                      Herbstferien Deutschland-weit fahren.Wir sagen DANKE für Ihre
                      Treue! Als Stammkundin bzw. Stammkunde profitieren Sie ab
                      September zwei Monate von unserer "Treuebonus-Aktion".
                    </div>
                  </div>
                </div>
                <div className={styles.landBum}>
                  <div className={styles.landBum1}>
                    <img
                      className={styles.pict2}
                      src={`${process.env.PUBLIC_URL}/assets/images/9_ticket.jpg`}
                    />
                  </div>
                  <div className={styles.landBum2}>
                    <div
                      onClick={() => this.props.history.push('/9faqv1')}
                      className={styles.bumHead}
                    >
                      Die Deutschland-Flatrate für Bus & Bahn
                    </div>
                    <div className={styles.bumText}>
                      Verkaufsstart in der BD-App und in den Kundencentern am
                      23.Mai.Das Ticketabo für die 2. Klasse in
                      Deutschland kostet im Juni, Juli und August 2022
                      dann auch nur neun Euro pro Monat.
                    </div>
                  </div>
                </div>
                <div className={styles.landBum}>
                  <div className={styles.landBum1}>
                    <img
                      className={styles.pict2}
                      src={`${process.env.PUBLIC_URL}/assets/images/umwelt_schutz.jpg`}
                    />
                  </div>
                  <div className={styles.landBum2}>
                    <div
                      onClick={() => this.props.history.push('/umweltv1')}
                      className={styles.bumHead}
                    >
                      Klimaschutz und Umweltproblemtik
                    </div>
                    <div className={styles.bumText}>
                      Unsere Umwelt liegt uns am Herzen. Seit Juli 2022 wird
                      unsere Dieselflotte mit Biokraftstoff getankt. Damit
                      leisten wir einen kleinen aber konkreten Beitrag gegen den
                      Klimawandel und sparen bei jeder Zugfahrt bis zu 90 % CO₂
                      unterwegs ein.
                    </div>
                  </div>
                </div>
              </div>
            )}
            {signactive && (
              <div>
                <div className={styles.landBing}>
                  <div className={styles.landBing1}>
                    <img
                      className={styles.pict2}
                      src={`${process.env.PUBLIC_URL}/assets/images/treue_bonus.jpg`}
                    />
                  </div>
                  <div className={styles.landBing2}>
                    <div className={styles.bumHead}>September & Oktober</div>
                    <div className={styles.bumText}>
                    Abonnenten-Treuebonus : An den Wochenenden und in den
                      Herbstferien Deutschland-weit fahren.Wir sagen DANKE für Ihre
                      Treue! Als Stammkundin bzw. Stammkunde profitieren Sie ab
                      September zwei Monate von unserer "Treuebonus-Aktion".
                    </div>
                  </div>
                  <div className={styles.landBing3}>
                    <VideoPlayer index={'Satz11'}/>
                  </div>
                </div>
                <div className={styles.landBing}>
                  <div className={styles.landBing1}>
                    <img
                      className={styles.pict2}
                      src={`${process.env.PUBLIC_URL}/assets/images/9_ticket.jpg`}
                    />
                  </div>
                  <div className={styles.landBing2}>
                    <div
                      onClick={() => this.props.history.push('/9faqv1')}
                      className={styles.bumHead}
                    >
                      Die Deutschland-Flatrate für Bus & Bahn
                    </div>
                    <div className={styles.bumText}>
                    Verkaufsstart in der BD-App und in den Kundencentern am
                      23.Mai.Das Ticketabo für die 2. Klasse in
                      Deutschland kostet im Juni, Juli und August 2022
                      dann auch nur neun Euro pro Monat.
                    </div>
                  </div>
                  <div className={styles.landBing3}>
                    <VideoPlayer index={'Satz11'} />
                  </div>
                </div>
                <div className={styles.landBing}>
                  <div className={styles.landBing1}>
                    <img
                      className={styles.pict2}
                      src={`${process.env.PUBLIC_URL}/assets/images/umwelt_schutz.jpg`}
                    />
                  </div>
                  <div className={styles.landBing2}>
                    <div
                      onClick={() => this.props.history.push('/umweltv1')}
                      className={styles.bumHead}
                    >
                      Klimaschutz und Umweltproblemtik
                    </div>
                    <div className={styles.bumText}>
                    Unsere Umwelt liegt uns am Herzen. Seit Juli 2022 wird
                      unsere Dieselflotte mit Biokraftstoff getankt. Damit
                      leisten wir einen kleinen aber konkreten Beitrag gegen den
                      Klimawandel und sparen bei jeder Zugfahrt bis zu 90 % CO₂
                      unterwegs ein.
                    </div>
                  </div>
                  <div className={styles.landBing3}>
                    <VideoPlayer  index={'Satz11'} />
                  </div>
                </div>
              </div>
            )}
            <FooterV1 />
          </div>
        </RightClick>
      </div>
    );
  }
}
TickerV1 = connect(
  (state) => ({
    bas: state.main.bas,
    signactive: state.main.signactive,
    active: state.overlay.active,
    device: state.main.device,
    vm: state.main.vm,
    debugMode: state.main.debugMode,
  }),
  {} // bind account loading action creator
)(TickerV1);
export default withRouter(TickerV1);
