import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { actionSetScenario, actionSetVariant } from '../../../redux/actions/actionMain';
import store from '../../../redux/store';
import styles from './VariantPick.module.scss';
class VariantPick extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const variants = [1, 2, 3, 4];
    const scenarios = [1, 2, 3, 4];
    return (
      <div className={styles.pck1}>
        <table>
          <tbody>
            <tr>
              <th></th>
              {scenarios.map((entry) => (
                <th key={entry}>Scenario {entry}</th>
              ))}
            </tr>
            {variants.map((variant) => (
              <tr key={variant}>
                <td>Variant: {variant}</td>
                {scenarios.map((scenario) => (
                  <td key={scenario}>
                    <Button
                      size="massive"
                      onClick={() => {
                        this.props.history.push(
                          `/scenario/${scenario}/variant/${variant}?tk=testToken${Math.random()}&num=1234`
                        )
                        store.dispatch(actionSetScenario({scenario}))
                      store.dispatch(actionSetVariant({variant}))} 
                     }
                    >
                      {`${variant}`}
                    </Button>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(VariantPick);
