import { SET_OVERLAY_VIDEO } from '../actions/actionOverlay';

const effectVuppetmaster = (action, getState, dispatch) => {
  switch (action.type) {
    case SET_OVERLAY_VIDEO:
      break;
    default:
      break;
  }
};

export default effectVuppetmaster;
