import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Breadcrumb, Button, Icon } from 'semantic-ui-react';
import FooterV1 from '../../../../2_molecules/FooterV1/FooterV1';
import HeadV1 from '../../../../2_molecules/HeadV1/HeadV1';
import VideoPlayer2 from '../../../../2_molecules/VideoPlayer2/VideoPlayer2';
import styles from './NoTicketsV1.module.scss';
import { connect } from 'react-redux';
import VideoPlayer from '../../../../2_molecules/VideoPlayer2/VideoPlayer';

class NoTicketsV1 extends Component {
  render() {
    const { signactive } = this.props;
    return (
      <div className={styles.page}>
        <HeadV1 />
        <div className={styles.breadContain}>
          <Breadcrumb>
            <Breadcrumb.Section
              onClick={() => this.props.history.push('/landingv1')}
              link
            >
              Startseite
            </Breadcrumb.Section>
            <Icon name="angle right" />
            <Breadcrumb.Section
              link
              onClick={() => this.props.history.push('/verbindungsv1')}
            >
              Verbindungssuche
            </Breadcrumb.Section>
            <Icon name="angle right" />
            <Breadcrumb.Section>No Tickets</Breadcrumb.Section>
          </Breadcrumb>{' '}
        </div>
        {!signactive && (
          <div className={styles.ticketContainer}>
            <div className={styles.ticketBing1}>
              <lbl className={styles.noTicket}>
                Leider gibt es unter Deinen Angaben keinen passenden Zug.
              </lbl>
            </div>

            <div className={styles.ticketBing2}>
              <Button
                basic
                floated="right"
                onClick={() => this.props.history.push('/verbindungsv1')}
              >
                Zurück zur Suche
              </Button>
            </div>
          </div>
        )}
        {signactive && (
          <div className={styles.ticketContainer}>
            <div className={styles.ticketBong}>
              <lbl className={styles.noTicket}>
                Leider gibt es unter Deinen Angaben keinen passenden Zug.
              </lbl>
              <div className={styles.btnNoTicket}>
                <Button
                  basic
                  onClick={() => this.props.history.push('/verbindungsv1')}
                >
                  Zurück zur Suche
                </Button>
              </div>
            </div>

            <div className={styles.ticketBong2}>
              <VideoPlayer index={'Satz2'} />
            </div>
          </div>
        )}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <FooterV1 />
      </div>
    );
  }
}
NoTicketsV1 = connect(
  (state) => ({
    search: state.ticket.search,
    signactive: state.main.signactive,
  }),
  {} // bind account loading action creator
)(NoTicketsV1);
export default withRouter(NoTicketsV1);
