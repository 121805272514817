export const actionSetVM = (vm) => ({
  type: 'SET_VM',
  vm: vm,
});

export const actionSetDevice = (device) => ({
  type: 'SET_DEVICE',
  device: device,
});

export const actionSetPath = (activePath) => ({
  type: 'SET_PATH',
  activePath: activePath,
});

export const actionSetDebugMode = (debugMode) => ({
  type: 'SET_DEBUG_MODE',
  debugMode: debugMode,
});

export const actionSetApiBase = (apibase) => ({
  type: 'SET_APIBASE',
  apibase: apibase,
});

export const actionSetViewport = (viewport) => ({
  type: 'SET_VIEWPORT',
  viewport: viewport,
});

export const actionSetXState = (xstate) => ({
  type: 'SET_XSTATE',
  xstate: xstate,
});

export const actionSetSignactive = (signactive) => ({
  type: 'SET_SIGNACTIVE',
  signactive: signactive ,
});


export const actionSetBas = (bas) => ({
  type: 'SET_BAS' ,
  bas: bas,
})
export const actionSetVariant = (variant) => ({
  type: 'SET_VARIANT',
  //variant festgestellt um zu publizieren.
  variant: variant ,
})
export const actionSetScenario = (scenario) => ({
  type: 'SET_SCENARIO',
  // scenario festgestellt um zu publizieren
  scenario: scenario ,
})
export const actionSetBought = (bought) => ({
  type: 'SET_BOUGHT',
  bought: bought,
})
export const actionSetToken = (token) => ({
  type: 'SET_TOKEN',
  token: token ,
})
export const actionSetParamNumber = (number) => ({
  type: 'SET_PARAM_NUMBER',
  param_number: number ,
})
export const actionSetBigScreen = (bigScreen) => ({
  type: 'SET_BIGSCREEN' ,
  bigScreen: bigScreen,
})
export const actionSetLanguage = (language) => ({
  type: 'SET_LANGUAGE',
  language: language,
});
export const actionSetEnd = (end) => ({
  type: 'SET_END',
  end: end,
});
export const actionSetOpenModal = (openModal) => ({
  type: 'SET_OPENMODAL',
  openModal: openModal,
});
