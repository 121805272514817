import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './SubTitles.module.scss';

class SubTitles extends Component {
  constructor(props) {
    super(props);
    this.state = { screenText: [], text: [], index: 0 };

    setTimeout(() => {
      this.setupText(this.props.text);
    }, 100);

    this._intervalID = 0;
  }

  componentWillUnmount() {
    if (this._intervalID) clearInterval(this._intervalID);
  }

  setupText(incomeText) {
    console.log('#### setuptext');
    const words = incomeText.split(' ');
    this.setState({ screenText: [], index: 0 });

    let screenText = [];

    this._intervalID = setInterval(() => {
      const word = words.shift();
      if (word === undefined) {
        clearInterval(this._intervalID);
      } else {
        const numLetters = screenText.join(' ').length;
        if (numLetters > 17) {
          screenText = [];
        }
        screenText.push(word);
        this.setState({
          screenText,
        });
      }
    }, 650);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.text && nextProps.text !== this.props.text) {
      this.setupText(nextProps.text);
    }
  }

  componentDidMount() {}

  componentWillUnmount() {
    if (this._intervalID) clearInterval(this._intervalID);
  }

  render() {
    const { screenText } = this.state;
    console.log(screenText.join(' '));
    return <div className={styles.main}>{screenText.join(' ')} </div>;
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
SubTitles = connect(
  (state) => ({
    position: state.overlay.position,
    video: state.overlay.video,
    active: state.overlay.active,
  }),
  {} // bind account loading action creator
)(SubTitles);

export default SubTitles;
